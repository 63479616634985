<template lang='pug'>
.resume-editor
  template(v-if='value')

    template(v-if='showConfiguration')
      form-group(label='Configuration')
        form-row
          form-input(
            v-model='value.name'
            label='Resume Name'
            instructions='Custom resume name. Used to identify your different resumes.'
            required
          )

    form-row
      form-input(
        v-model='value.firstName'
        :placeholder='effectiveResume.firstName'
        label='First Name'
        :required='!effectiveResume.firstName')

      form-input(
        v-model='value.lastName'
        :placeholder='effectiveResume.lastName'
        label='Last Name'
        :required='!effectiveResume.lastName')

    form-row
      form-input(
        v-model='value.email' 
        type='email'
        label='Email Address'
        :placeholder='effectiveResume.email'
        :required='!effectiveResume.email')

      form-input(
        v-model='value.phone'
        type='phone'
        label='Phone Number'
        :placeholder='effectiveResume.phone'
        :required='!effectiveResume.phone')

    form-row
      form-input(
        v-model='value.jobTitle'
        type='string'
        label='Current Job Title'
        :placeholder='effectiveResume.jobTitle'
        :max-length='150')

    online-presence-input(v-model='value')
    address-input(v-model='value.address')

    form-row
      form-input.bio-input(
        v-model='value.customization.bio' type='textarea'
        label='Career Objective'
        :max-length='400'
        instructions='Briefly state your career objective or summarize what makes you stand out.'
        placeholder=''
      )
      
    form-row
      resume-list-editor(v-model='value.careerHistory' type='career')
    form-row
      resume-list-editor(v-model='value.educationHistory' type='education')
    form-row
      resume-list-editor(v-model='value.skills' type='skills')

    form-row
      form-input.bio-input(
        v-model='value.customization.activities' type='textarea'
        label='Activities'
        :max-length='400'
        instructions='Use this section to highlight your relevant passions, activities, and how you like to give back.'
        placeholder=''
      )
      
</template>

<script>
import _ from 'lodash';

import { makeEffectiveResume } from '@/utils/resume';

const components = {
  'online-presence-input': require('@/components/forms/online-presence-input')
    .default,
  'address-input': require('@/components/forms/address-input').default,
  'resume-list-editor': require('@/components/other/resume-list-editor').default,
};

export default {
  components,
  props: {
    value: {
      type: Object, // passed in via v-model
      default: () => {},
    },
    user: { type: Object },
    showConfiguration: {
      type: Boolean,
      default: () => true,
    },
  },
  computed: {
    effectiveResume() {
      return makeEffectiveResume(this.value, this.user);
    },
  },
  methods: {},
};
</script>

<style lang='less'>
.resume-editor {
}
</style>