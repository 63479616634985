<template lang='pug'>
.onboarding-profile-step

  h3 Your Profile
  p Your profile acts as a default for your resumes.
  user-identity-input(v-model='user')
  online-presence-input(v-model='user')
  address-input(v-model='user.address')

  save-bar(
    ref='saveBar'
    :draft-value='user' :store-value='storeUser'
    :update-request='updateUserRequest'
    :save-disabled='$vv.$error'
    @save='saveButtonHandler' @reset='resetToStoreState'
  )

</template>

<script>
import _ from 'lodash';
import { mapGetters } from 'vuex';

import { vuelidateGroupMixin } from '@/components/forms/vuelidate-group';
import { mapRequestStatuses } from '@/utils/vuex-api-utils';
import { buildSkillOptions } from '@/utils/enum';

const components = {
  'user-identity-input': require('@/components/forms/user-identity-input')
    .default,
  'online-presence-input': require('@/components/forms/online-presence-input')
    .default,
  'address-input': require('@/components/forms/address-input').default,
};

export default {
  components,
  mixins: [vuelidateGroupMixin],
  data() {
    return {
      user: {},
    };
  },
  computed: {
    ...mapGetters('enums', [
      'skillOptions',
      'industryOptions',
      'languageOptions',
      'companySizeOptions',
      'countryOptions',
    ]),
    ...mapGetters('authUser/profile', {
      storeUser: 'user',
    }),
    ...mapRequestStatuses({
      getUserRequest: 'authUser/profile/GET_USER',
      updateUserRequest: 'authUser/profile/UPDATE_USER',
    }),
    computedSkillOptions() {
      return buildSkillOptions(this.skillOptions, this.storeUser.company);
    },
  },
  watch: {
    storeUser: {
      // deep: true,
      handler() {
        this.resetToStoreState();
      },
    },
  },
  beforeMount() {
    this.resetToStoreState();
  },
  methods: {
    resetToStoreState() {
      this.user = _.cloneDeep(this.storeUser);
    },
    async saveButtonHandler() {
      if (this.$hasError()) return;
      await this.$store.dispatchApiAction(
        'authUser/profile/UPDATE_USER',
        this.user,
      );
    },
  },
  beforeRouteLeave(to, from, next) {
    return next(this.$refs.saveBar.warnAboutUnsavedChanges());
  },
};
</script>

<style lang='less'>
.onboarding-profile-step {
  .bio-input textarea {
    min-height: 250px;
  }
}
</style>
