<template lang='pug'>
.landing-page-list(
  :class='computedClasses'
  :style='computedStyles'
)
  slot

</template>


<script>
const components = {
};

export default {
  components,
  props: {
    compact: Boolean,
  },
  computed: {
    computedClasses() {
      return {
        'landing-page-list--compact': this.compact,
      };
    },
    computedStyles() {
      return {
      };
    },
  },
};
</script>

<style lang='less'>
.landing-page-list {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  margin: 30px 0;

  @media @mq-small-only {
    display: block;
  }


  > .landing-page-list-item {
    width: 30%; // 3 columns with a little margin
    margin: 20px 00px;
    // margin: 20px;

    @media @mq-small-only {
      width: 100%;
      margin: 30px 0;
      // flex: none;
    }
  }

  &.landing-page-list--compact {
    justify-content: center;
    > .landing-page-list-item {
      margin: 25px;
      width: 250px;
      @media @mq-small-only {
        width: unset;
      }
    }
  }

}
</style>
