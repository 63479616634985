<template lang='pug'>
.resume-preview-traditional
  .resume-header
    .resume-title
      span.resume-title-first-name {{ resume.firstName }}
      span.resume-title-last-name {{ resume.lastName }}
    .resume-subtitle
      .resume-subtitle-item.resume-address
        .line-1 {{ resume.address.line1 }}
        .line-2 {{ resume.address.line2 }}
        .city-state {{ resume.address.city }}, {{ resume.address.state }}, {{ resume.address.country }} {{ resume.address.postalCode }}
      .resume-split
      .resume-subtitle-item.resume-phone {{ resume.phone }}
    .resume-links
      template(v-for='link in links' )
        a.resume-link(:href='link.url' target='_blank') {{ link.text }}
        .resume-split(v-if='!link.last')
  .resume-divider
  .resume-body
    .resume-summary {{ resume.customization.bio }}
    .resume-experience(v-if='experienceList')
      h3 Experience
      .resume-experience-content
        .resume-experience-entry(v-for='experience in experienceList')
          .entry-date 
            span {{ experience.years }}
          .entry-title-info
            span.entry-title {{ experience.jobTitle }}
            span.entry-subtitle {{ experience.company }}
          .entry-details {{ experience.description }}
    .resume-experience.resume-education(v-if='educationList')
      h3 Education
      .resume-experience-content
        .resume-experience-entry(v-for='experience in educationList')
          .entry-date 
            span {{ experience.years }}
          .entry-title-info
            span.entry-title {{ experience.school }}
            span.entry-subtitle {{ experience.degree }}
          .entry-details {{ experience.description }}
    .resume-skills
      h3 Skills
      ul
        li(v-for='skill in skillList') 
          span.skill-title {{ skill.title }}
          template(v-if='skill.description')
            span {{" - "}} 
            span.skill-description {{ skill.description }}
    .resume-activities
      h3 Activities
      p {{ resume.customization.activities }}
  
</template>

<script>
import _ from 'lodash';

import { makeResumeLinksArray } from '@/utils/resume';

const components = {};

export default {
  components,
  props: {
    resume: { type: Object },
  },
  computed: {
    links() {
      const linksData = makeResumeLinksArray(this.resume);
      return linksData.map((x, i) => ({
        ...x,
        last: i === linksData.length - 1,
      }));
    },
    experienceList() {
      return this.resume.careerHistory;
    },
    educationList() {
      return this.resume.educationHistory;
    },
    skillList() {
      return this.resume.skills;
    },
  },
  methods: {},
};
</script>

<style lang='less'>
.resume-preview-traditional {
  padding: 1.9cm; // ~Printer Paper Margins

  .resume-header {
    text-align: center;

    .resume-title {
      text-transform: uppercase;
      font-size: 4em;
      line-height: 1.1em;
      display: flex;
      flex-wrap: wrap;
      justify-content: center;

      .resume-title-last-name {
        margin-left: 8px;
        font-weight: bold;
      }
    }

    .resume-split {
      height: 6px;
      width: 6px;
      margin: 0 10px;
      background-color: #555;
      border-radius: 50%;
      display: inline-block;
      align-self: center;
    }

    .resume-subtitle {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: center;
      align-items: flex-start;

      .resume-subtitle-item {
        flex: 1 0 auto;
        padding: 0 6px;
        max-width: 250px;
      }

      .resume-address {
        text-align: right;
      }

      .resume-phone {
        text-align: left;
      }
    }

    .resume-links {
      color: green;

      a {
        color: inherit;
      }
    }
  }

  .resume-divider {
    border-top: 1px solid #00000060;
    margin: 30px -1.9cm;
  }

  .resume-body {
    h3 {
      text-transform: uppercase;
      font-weight: bold;
    }

    .resume-summary {
      margin-bottom: 20px;
    }

    .resume-experience {
      .resume-experience-content {
        border-left: dotted 2px rgba(0, 0, 0, 0.5);
        padding: 2px 0;
        padding-left: 40px;

        .resume-experience-entry {
          margin: 14px 0;

          .entry-date {
            font-weight: bold;
            text-transform: uppercase;
            font-size: 0.9em;
            color: rgba(0, 0, 0, 0.8);
          }

          .entry-title-info {
            text-transform: uppercase;
            font-size: 1.2em;

            .entry-title {
              color: green;
              font-weight: bold;
            }

            .entry-subtitle {
              margin-left: 6px;
            }
          }

          .entry-details {
          }
        }
      }
    }

    .resume-skills {
      ul {
        padding-left: 20px;
      }
    }
  }
}
</style>