<template lang='pug'>
.resume-preview
  template(v-if='resume')
    template(v-if='presentation === "modern"')
      //-- TODO
    template(v-else)
      resume-preview-traditional(:resume='effectiveResume')
</template>

<script>
import _ from 'lodash';

import { makeEffectiveResume } from '@/utils/resume';

const components = {
  'resume-preview-traditional': require('@/components/other/resume-preview-traditional')
    .default,
};

export default {
  components,
  props: {
    resume: { type: Object, required: true },
    user: { type: Object, required: false },
    presentationTypeOverride: {
      type: String,
    },
  },
  computed: {
    presentation() {
      return this.presentationOverride || this.resume.presentationType;
    },
    effectiveResume() {
      if (this.user) {
        return makeEffectiveResume(this.resume, this.user);
      }

      return this.resume;
    },
  },
  methods: {},
};
</script>

<style lang='less'>
.resume-preview {
  padding: 3em 0;
  border: solid 1px #0000003d;
  background: white;
}
</style>