<template lang='pug'>
main-layout#page-home(full-width)
  .home-hero
    .home-hero-logo
      logo-svg
    .home-hero-content 
      v-button(to-named-route='resume-signup' theme='pink') Build Your Free Resume

</template>

<script>
import { makeSeoRefs } from '@/utils/meta';

const components = {
  'main-layout': require('@/app-public/common/layout/main-layout').default,
  'landing-page-section': require('@/app-public/common/layout/landing-page-section').default,
  'landing-page-list': require('@/app-public/common/layout/landing-page-list').default,
  'landing-page-list-item': require('@/app-public/common/layout/landing-page-list-item').default,

  // why leadereq icons
  'science-icon': require('!vue-svg-loader!@/assets/images/large-icons/line/science.svg').default,
  'world-class-icon': require('!vue-svg-loader!@/assets/images/large-icons/line/5-star.svg').default,
  'arrows-360-icon': require('!vue-svg-loader!@/assets/images/large-icons/line/360.svg').default,
  'flexible-icon': require('!vue-svg-loader!@/assets/images/large-icons/line/world-communication.svg').default,
  'tracking-icon': require('!vue-svg-loader!@/assets/images/large-icons/line/head-graph.svg').default,
  'affordable-icon': require('!vue-svg-loader!@/assets/images/large-icons/line/save-money.svg').default,

  // enterprise icons
  'expand-icon': require('!vue-svg-loader!@/assets/images/large-icons/web-seo/expand.svg').default,
  'graph-icon': require('!vue-svg-loader!@/assets/images/large-icons/web-seo/graph.svg').default,
  'config-icon': require('!vue-svg-loader!@/assets/images/large-icons/web-seo/config.svg').default,

  // logo
  'logo-svg': require('!vue-svg-loader!@/assets/images/logos/a360-logo.svg').default,
};

export default {
  components,
  metaInfo: {
    title: 'Corporate Matchmakers',
    link: makeSeoRefs(''),
  },
  computed: {},
};
</script>

<style lang='less'>
#page-home {
  .home-hero {
    display: flex;
    justify-content: space-around;
    align-items: center;
    overflow: hidden;
    position: relative;
    flex-wrap: wrap;

    .home-hero-logo {
      flex: 1 0 100%;
      margin: 46px 0 18px;

      svg {
        margin: auto;
        display: block;
        width: 320px;
      }
    }

    .home-hero__title {
      display: block;
      flex-grow: 1;
      padding: 0 30px;
      text-align: right;
      h2 {
        font-weight: normal;
        font-size: 20px;
      }
      @media @mq-small-only {
        text-align: center;
        margin: 20px 0;
      }
    }

    .home-hero__coach-photo {
      height: 400px;
      width: 156px;
      flex: 156px 0 0;
      margin: 0 20px;
      z-index: 1;
      img {
        max-width: 100%;
      }
      @media @mq-small-only {
        display: none;
      }

    }
    .home-hero__sparkles-photo {
      flex-grow: 1;
      padding: 0 30px;
      img {
        max-width: 100%;
        max-height: 300px;
      }
      @media @mq-small-only {
        display: none;
      }
    }
  }

  .how-it-works {
    display: flex;

    @media @mq-small-only {
      display: block;
    }

    .how-it-works__item {
      padding: 15px;
      margin: 10px;
      flex: 1 0 0;
      h3 {
        font-weight: bold;
        color: @brand-pink;
      }
      > img {
        height: 150px;
        margin-bottom: 10px;
      }
    }
  }
}
</style>
