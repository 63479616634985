import _ from 'lodash';
import Vue from 'vue';
// import * as Sentry from '@sentry/browser';

import { buildApiActions } from '../utils/vuex-api-utils';

const allPropsFilled = (obj, props) => obj && _.every(props, (p) => !_.isNil(obj[p]));

const REQUIRED_GOOGLE_SCOPES = [
  'https://www.googleapis.com/auth/calendar.events.readonly',
  'https://www.googleapis.com/auth/calendar.readonly',
];

export default {
  namespaced: true,
  state() {
    return {
      user: {},
    };
  },
  getters: {
    userId: (state) => state.user.id,

    user: (state) => state.user,
    isVerified: (state) => Boolean(state.user && state.user.isVerified),

    bestName: (state) => state.user.firstName || 'Friend',
    userDataIsLoaded: (state) => state.user.createdAt,

    isBasicInfoComplete: (state) => {
      if (!allPropsFilled(state.user, [
        'firstName', 'lastName', 'phone', 'email', 'address',
      ])) return false;
      if (!allPropsFilled(state.user.address, [
        'line1', 'city', 'state', 'country', 'postalCode',
      ])) return false;
      if (state.user.isExecutive && !state.user.jobTitle) return false;
      if (state.user.isCoach && !state.user.profilePic) return false;
      return true;
    },

    calendarConnectionStatus: (state) => {
      if (!_.get(state, 'user.googleAuthDetails.id')) {
        return 'not_connected';
      }

      const scopes = _.get(state, 'user.googleAuthDetails.scopes', []);
      if (_.difference(REQUIRED_GOOGLE_SCOPES, scopes).length > 0) {
        return 'insufficient_permissions';
      }
      if (!_.find(state.user.externalCalendarDetails, { affectsAvailability: true })) {
        return 'no_calendars_selected';
      }
      return 'connected';
    },
    isCalendarConnectComplete: (state, getters) => getters.calendarConnectionStatus === 'connected',
  },
  ...buildApiActions({
    GET_USER: {
      action: (ctx, payload) => ({
        method: 'get',
        url: `/users/${ctx.getters.userId}`,
      }),
      mutation: (state, { response }) => {
        state.user = response;

        // add user/business ids to Sentry errors
        // Sentry.configureScope((scope) => {
        //   scope.setUser({
        //     id: response.id,
        //     companyId: response.companyId,
        //     email: response.email,
        //   });
        // });
      },
    },
    UPDATE_USER: {
      action: (ctx, payload) => ({
        method: 'patch',
        url: `/users/${ctx.getters.userId}`,
        params: _.omit(payload, 'id'),
      }),
      mutation: (state, { response }) => { state.user = response; },
    },
    UPDATE_USER_EVAL_RESPONDENTS: {
      action: (ctx, payload) => ({
        method: 'patch',
        url: `/users/${ctx.getters.userId}/evaluation-respondents`,
        params: _.omit(payload, 'id'),
      }),
      mutation: (state, { response }) => {
        state.user = response;
      },
    },

    // LEADEREQ/COMPANY ADMIN ONLY
    ADMIN_DELETE_USER: {
      action: (ctx, payload) => ({
        method: 'delete',
        url: `/users/${ctx.getters.userId}`,
        params: {
          fullDelete: payload.fullDelete || false,
        },
      }),
      mutation: (state, { response }) => {
        state.user = {};
      },
    },

    // PUBLIC SITE ONLY
    ADD_USER_AGREEMENT: {
      action: (ctx, payload) => ({
        method: 'post',
        url: `/users/${ctx.getters.userId}/legal-agreement`,
        params: payload,
      }),
      mutation: (state) => {
        // TODO: the endpoint currently returns nothing so we set this here instead
        // but we should make the endpoint return a response and use it
        state.user.legalAgreementVersion = process.env.TERMS_AND_POLICY_UPDATED_AT;
      },
    },
    UPDATE_USER_PASSWORD: {
      action: (ctx, payload) => ({
        method: 'post',
        url: `/users/${ctx.getters.userId}/set-password`,
        params: payload,
      }),
      mutation: (state, { response }) => {
      },
    },
    // ADD_USER_AGREEMENT: {
    //   action: (ctx, payload) => ({
    //     method: 'post',
    //     url: `/users/${ctx.getters.userId}/legal-agreement`,
    //     params: payload,
    //   }),
    // },

    // COACHES /////////////////////////////////////////////////////////////////////////////////////
    TRIGGER_USER_MATCHING: {
      action: (ctx, payload) => ({
        method: 'post',
        url: `/users/${ctx.getters.userId}/match`,
        afterSuccess() {
          ctx.dispatch('api-GET_USER_MATCHING_COACHES');
        },
      }),
    },
    GET_USER_MATCHING_COACHES: {
      action: (ctx, payload) => ({
        method: 'get',
        url: `/users/${ctx.getters.userId}/matching-coaches`,
      }),
      mutation: (state, { response }) => {
        state.userMatchingCoaches = _.keyBy(response, 'id');
        _.each(response, (coach) => {
          Vue.set(state.coaches, coach.id, coach);
        });
      },
    },

    GET_USER_CURRENT_COACH: {
      action: (ctx, payload) => ({
        method: 'get',
        url: `/coach-profiles/${ctx.state.user.currentCoachId}`,
      }),
      mutation: (state, { response }) => {
        state.userCurrentCoach = response;
        Vue.set(state.coaches, response.id, response);
      },
    },

    GET_COACH_BY_SLUG: {
      action: (ctx, payload) => ({
        method: 'get',
        url: `/coach-profiles/${payload.slug}`,
        keyRequestStatusBy: payload.slug,
      }),
      mutation: (state, { response }) => {
        Vue.set(state.coaches, response.id, response);
      },
    },

    SELECT_USER_COACH: {
      action: (ctx, payload) => ({
        method: 'post',
        url: `/users/${ctx.getters.userId}/select-coach`,
        params: payload, // coachId
      }),
      mutation: (state, { response }) => { state.user = response; },
    },
    FIRE_USER_COACH: {
      action: (ctx, payload) => ({
        method: 'post',
        url: `/users/${ctx.getters.userId}/fire-coach`,
        params: payload,
      }),
      mutation: (state, { response }) => { state.user = response; },
    },

    // CALENDARS ///////////////////////////////////////////////////////////////////////////////////
    CONNECT_CALENDAR_ACCOUNT: {
      action: (ctx, payload) => ({
        method: 'post',
        url: `/users/${ctx.getters.userId}/connect-calendar`,
        params: payload, // code -- from oauth flow
      }),
      mutation: (state, { response }) => { state.user = response; },
    },
    REFRESH_CALENDARS: {
      action: (ctx, payload) => ({
        method: 'post',
        url: `/users/${ctx.getters.userId}/refresh-calendars`,
        params: payload,
      }),
      mutation: (state, { response }) => { state.user = response; },
    },
    UPDATE_CALENDAR: {
      action: (ctx, payload) => ({
        method: 'patch',
        url: `/users/${ctx.getters.userId}/calendars/${payload.id}`,
        params: payload,
      }),
      mutation: (state, { response }) => {
        state.user.externalCalendarDetails = [
          ..._.reject(state.user.externalCalendarDetails, { id: response.id }),
          response,
        ];
      },
    },

    CREATE_COACHING_CALENDAR: {
      action: (ctx) => ({
        method: 'post',
        url: `/users/${ctx.getters.userId}/setup-shared-calendar`,
      }),
      mutation: (state, { response }) => { state.user = response; },
    },

    DESTROY_COACHING_CALENDAR: {
      action: (ctx) => ({
        method: 'post',
        url: `/users/${ctx.getters.userId}/destroy-shared-calendar`,
      }),
      mutation: (state, { response }) => { state.user = response; },
    },

    TOGGLE_COACH_PAUSE: {
      action: (ctx, payload) => ({
        method: 'post',
        url: `/coaches/${ctx.getters.userId}/${payload.pause ? 'pause' : 'unpause'}`,
      }),
      mutation: (state, { response }) => { state.user = response; },
    },

    // COACH APPROVAL FLOW /////////////////////////////////////////////////////////////////////////
    ACCEPT_COACH: {
      action: (ctx) => ({
        method: 'post',
        url: `/coaches/${ctx.getters.userId}/accept`,
      }),
      mutation: (state, { response }) => { state.user = response; },
    },
    REJECT_COACH: {
      action: (ctx) => ({
        method: 'post',
        url: `/coaches/${ctx.getters.userId}/reject`,
      }),
      mutation: (state, { response }) => { state.user = response; },
    },
    ACTIVATE_COACH: {
      action: (ctx) => ({
        method: 'post',
        url: `/coaches/${ctx.getters.userId}/activate`,
      }),
      mutation: (state, { response }) => { state.user = response; },
    },

  }, {
    actions: {
      // called after loading the user id from local storage on app initialization
      setUserId: async (ctx, userId) => {
        await ctx.commit('SET_USER_ID', userId);
        await ctx.dispatch('api-GET_USER');
      },
      // called directly after login as the login payload includes user data
      setUserData: async (ctx, user) => {
        await ctx.commit('SET_USER_DATA', user);
      },
      removeEvaluationRespondentByEmail: async (ctx, email) => {
        await ctx.dispatch('api-UPDATE_USER_EVAL_RESPONDENTS', {
          evaluationRespondents: _.reject(ctx.state.user.evaluationRespondents, { email }),
        });
      },
    },
    mutations: {
      SET_USER_ID: (state, userId) => {
        state.user = { id: userId };
      },
      SET_USER_DATA: (state, user) => {
        state.user = user;
      },
      CLEAR_USER: (state) => {
        state.user = {};
      },
    },
  }),
};
