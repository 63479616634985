import Vue from 'vue';
import Vuex from 'vuex';
import _ from 'lodash';

import {
  initializeStore,
} from '@/utils/vuex-api-utils';

Vue.use(Vuex);

const store = initializeStore({
  state: {},
  modules: {
    auth: require('@/shared-vuex-modules/auth-module').default,
    enums: require('@/shared-vuex-modules/enums-module').default,
    window: require('@/shared-vuex-modules/window-module').default,

    // stores things related to the current logged in user
    authUser: {
      namespaced: true,
      modules: {
        resumes: require('@/shared-vuex-modules/resumes-module').default,
        profile: require('@/shared-vuex-modules/profile-module').default,
        assessment: require('@/shared-vuex-modules/assessment-module').default,
        documents: require('@/shared-vuex-modules/documents-module').default,
      },
    },

    // stores things related to the currently "selected user"
    selectedUser: {
      namespaced: true,
      modules: {
        resumes: require('@/shared-vuex-modules/resumes-module').default,
        /*
        profile: require('@/shared-vuex-modules/profile-module').default,
        assessment: require('@/shared-vuex-modules/assessment-module').default,
        documents: require('@/shared-vuex-modules/documents-module').default,
        */
      },
    },

  },
  strict: process.env.NODE_ENV !== 'production',
});
window.store = store;

export default store;
