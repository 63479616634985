<template lang='pug'>
//- TODO: v-if='value' is stopping some errors when the address being passed in is undefined
//- need to fix this and how we bind here...

//- TODO make required and requiredWarning work with a single error
//- message for the whole block

.value-identity-input
  form-row
    form-input(v-model='value.firstName' label='First name' required :maxLength="50")
    form-input(v-model='value.lastName' label='Last name' required :maxLength="50")
  form-row
    form-input(v-model='value.email' type='email' label='Email' required :maxLength="100")
    form-input(v-model='value.phone' type='phone' label='Phone' :maxLength="16")
  form-row
    form-input(v-model='value.jobTitle'
      label='Current Job Title'
      :maxLength="50"
    )

</template>

<script>
import { mapGetters } from 'vuex';

import loadExternalScriptsMixin from '@/mixins/load-external-scripts';

import { BEST_CONTACT_TIMES } from '@/data/best-contact-times';
import { TIMEZONES } from '@/data/timezones';

import {
  stateOptionsForCountry,
  postalCodeLabel,
  stateLabel,
} from '@/utils/local';

// TODO: fix v-model handling
// https://github.com/vuejs/vue/issues/4373
// technically we shouldnt be changing the passed in object directly
// but keeping a local copy and calling `$emit('input')` on changes

const components = {
  'profile-photo': require('@/components/other/profile-photo').default,
};

export default {
  components,
  mixins: [loadExternalScriptsMixin],
  props: {
    value: {
      type: Object, // passed in via v-model
      default: () => {},
    },
    compact: Boolean,
    required: Boolean,
    requiredWarning: Boolean,
    requiredMessage: String,
    disabled: Boolean,
  },
  computed: {
    ...mapGetters('enums', [
      'countryOptions',
      'industryOptions',
      'roleOptions',
      'careerSpeakOptions',
    ]),
  },
  methods: {
    stateOptionsForCountry,
    postalCodeLabel,
    stateLabel,
  },
};
</script>

<style lang='less'>
</style>
