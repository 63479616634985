import _ from 'lodash';
import Vue from 'vue';

import { buildApiActions } from '@/utils/vuex-api-utils';

export default {
  namespaced: true,
  state() {
    return {
      assessment: null,
      nextQuestion: null,
    };
  },
  getters: {
    userId: (s, g, rs, rg) => rg[`${s._moduleParent}profile/userId`],

    assessment: (state) => state.assessment,
    nextAssessmentQuestion: (state) => state.nextQuestion,
    results: (state) => _.get(state, 'assessment.results'),
  },
  ...buildApiActions({
    GET_ASSESSMENT_DETAILS: {
      action: (ctx, payload) => ({
        method: 'get',
        url: `/users/${ctx.getters.userId}/assessment`,
      }),
      mutation: (state, { response }) => {
        state.assessment = response;
      },
    },
    GET_NEXT_ASSESSMENT_QUESTION: {
      action: (ctx, payload) => ({
        method: 'get',
        url: `/users/${ctx.getters.userId}/assessment/next-question`,
      }),
      mutation: (state, { response }) => {
        state.nextQuestion = response;
      },
    },
    ANSWER_ASSESSMENT_QUESTION: {
      action: (ctx, payload) => ({
        method: 'post',
        url: `/users/${ctx.getters.userId}/assessment/answer-question`,
        params: payload, // questionId, answer
        afterSuccess: async (response) => {
          // the question response tells us when the assessement is complete
          // but that info also lives on the user and is used from there elsewhere in the UI
          if (response.assessmentComplete) {
            ctx.dispatch('api-GET_ASSESSMENT_DETAILS');
            ctx.dispatch(`${ctx.state._moduleParent}profile/api-GET_USER`, null, { root: true });
          }
        },
      }),
      mutation: (state, { response }) => {
        state.nextQuestion = response;
      },
    },
    RESET_ASSESSMENT: {
      action: (ctx) => ({
        method: 'delete',
        url: `/users/${ctx.getters.userId}/assessment`,
        afterSuccess: async (response) => {
          // the question response tells us when the assessment is complete
          // but that info also lives on the user and is used from there elsewhere in the UI
          ctx.dispatch(`${ctx.state._moduleParent}profile/api-GET_USER`, null, { root: true });
        },
      }),
      mutation: (state, { response }) => { state.assessment = null; },
    },
  }),


};
