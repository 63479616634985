<template lang='pug'>
.landing-page-list-item(
  :class='computedClasses'
  :style='computedStyles'
)
  img.landing-page-list-item__image(v-if='imageUrl' :src='imageUrl')
  .landing-page-list-item__icon
    slot(name='icon')
  h3.landing-page-list-item__title {{ title }}
  .landing-page-list-item__subtitle {{ subtitle }}
  slot

</template>


<script>
const components = {
};

export default {
  components,
  props: {
    title: String,
    subtitle: String,
    imageUrl: String,
    iconUrl: String,
  },
  computed: {
    computedClasses() {
      return {
      };
    },
    computedStyles() {
      return {
      };
    },
  },
};
</script>

<style lang='less'>
.landing-page-list-item {
  .landing-page-list-item__image {
    margin-bottom: 20px;
    @media @mq-small-only {
      max-height: 250px;
      margin-bottom: 10px;
    }
  }
  h3.landing-page-list-item__title {
    font-weight: bold;
    // font-size: 22px;
    // line-height: 1.4em;
    // color: @brand-pink;
    margin-bottom: 0px;
  }
  .landing-page-list-item__subtitle {
    // font-style: italic;
    font-size: 18px;
    font-size: 11px;
    text-transform: uppercase;
  }

  .landing-page-list-item__icon {
    width: 80px;
    height: 80px;
    margin: 0 auto 15px;
    &:empty { display: none; }

    svg {
      fill: currentColor;
    }
  }

}
</style>
