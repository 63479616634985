import _ from 'lodash';
import {
  buildApiActions,
} from '@/utils/vuex-api-utils';
import {
  makeEffectiveResume,
} from '@/utils/resume';

function updateTargetResume(ctx, response) {
  // Also Update Targeted Resume
  const {
    resumes,
  } = ctx.getters;

  const resumeIndex = resumes.findIndex((x) => x.id === response.id);

  console.log('Resume: ', resumeIndex);

  if (resumeIndex > -1) {
    _.assign(resumes[resumeIndex], response);
  }
}

export default {
  namespaced: true,
  state() {
    return {
      resumesForUserId: null,
      resumes: null,
      newResumeId: null,
      currentResumeId: null,

      // Used for loading/viewing shared resumes.
      inputResumeShareKey: null,
      sharedResume: null,
    };
  },
  getters: {
    userId: (s, g, rs, rg) => {
      const userId = rg[`${s._moduleParent}profile/userId`];
      return userId;
    },
    user: (s, g, rs, rg) => {
      const user = rg[`${s._moduleParent}profile/user`];
      return user;
    },
    newResumeId: (state) => state.newResumeId,
    currentResumeId: (state) => state.currentResumeId,
    resumes: (state) => state.resumes,
    currentResume: (state) => state.resumes.find((x) => x.id === state.currentResumeId),
    hasResumesLoaded: (state, getters) => Boolean(state.resumes) && Boolean(getters.userId) && getters.userId === state.resumesForUserId,
    canLoadResumes: (state, getters) => Boolean(getters.userId),
    // Current Resume Accessors
    effectiveResume: (state, getters) => {
      const { currentResume, user } = getters;
      return makeEffectiveResume(currentResume, user);
    },
    resumeIsShared: (state, getters) => {
      const { currentResume, user } = getters;
      const { shared } = currentResume;
      return Boolean(shared);
    },
    resumeUrl: (state, getters) => {
      const { currentResume, user } = getters;
      const { shareKey } = currentResume;

      const baseUrl = window.location.origin;
      return `${baseUrl}/r/${shareKey}`;
    },
    // Shared Resume
    inputResumeShareKey: (state) => state.inputResumeShareKey,
    hasSharedResumeLoaded: (state, getters) => Boolean(state.sharedResume) && Boolean(state.inputResumeShareKey) && state.sharedResume.shareKey === state.inputResumeShareKey,
    sharedResume: (state, getters) => state.sharedResume,
  },
  ...buildApiActions({
    /**
     * Loads all resumes for the user.
     */
    LOAD_RESUMES: {
      action: (ctx, payload) => ({
        method: 'get',
        url: `/users/${ctx.getters.userId}/resumes`,
      }),
      mutation: (state, {
        ctx,
        response,
      }) => {
        state.resumes = response.resumes;
        state.resumesForUserId = ctx.getters.userId;
      },
    },
    /**
     * Loads the shared resume
     */
    LOAD_SHARED_RESUME: {
      action: (ctx, payload = {}) => ({
        method: 'get',
        url: `/shared/resumes/${payload.shareKey || ctx.getters.inputResumeShareKey}`,
      }),
      mutation: (state, {
        ctx,
        response,
      }) => {
        state.sharedResume = response;
      },
    },
    /**
     * Creates a new Resume.
     */
    CREATE_RESUME: {
      action: (ctx, payload) => ({
        method: 'post',
        url: `/users/${ctx.getters.userId}/resumes`,
        params: payload,
      }),
      mutation: (state, {
        response,
      }) => {
        const resume = response;
        state.resumes.push(resume);
        state.newResumeId = resume.id;
      },
    },
    /**
     * Creates a new Resume.
     */
    UPDATE_RESUME: {
      action: (ctx, payload) => ({
        method: 'patch',
        url: `/users/${ctx.getters.userId}/resumes/${payload.id}`,
        params: payload,
      }),
      mutation: (state, {
        ctx,
        response,
      }) => {
        updateTargetResume(ctx, response);
      },
    },
    /**
     * Sets the shared state of a resume.
     */
    TOGGLE_SHARE_RESUME: {
      action: (ctx, payload) => ({
        method: 'put',
        url: `/users/${ctx.getters.userId}/resumes/${payload.id || ctx.getters.currentResumeId}/share`,
        params: payload,
      }),
      mutation: (state, {
        ctx,
        response,
      }) => {
        updateTargetResume(ctx, response);
      },
    },
    /**
     * Deletes a specific Resume.
     */
    DELETE_RESUME: {
      action: (ctx, payload) => ({
        method: 'delete',
        url: `/users/${ctx.getters.userId}/resumes/${payload.resumeId}`,
      }),
      mutation: (state, {
        payload,
      }) => {
        state.resumes = state.resumes.filter((x) => x.id !== payload.resumeId);
      },
    },
  }, {
    actions: {

      // called after loading the user id from local storage on app initialization
      setCurrentResumeId: async (ctx, resumeId) => {
        await ctx.commit('SET_CURRENT_RESUME_ID', resumeId);
        await ctx.dispatch('loadResumes');
      },

      /**
       * Used for loading the current user into the coaching module.
       *
       * Used to prepare access to Resume getter/setters.
       */
      loadResumes: async (ctx) => {
        const loadResumes = !ctx.getters.hasResumesLoaded;

        // console.log('Load resumes...', loadResumes);
        // console.log('User: ', ctx.getters.userId);

        if (loadResumes && ctx.getters.canLoadResumes) {
          await ctx.commit('CLEAR_RESUMES');
          await ctx.dispatch('api-LOAD_RESUMES');
        }
      },

      loadSharedResume: async (ctx, resumeShareKey) => {
        await ctx.commit('SET_INPUT_RESUME_SHARE_KEY', resumeShareKey);

        console.log('Loading shared resume: ', resumeShareKey);
        if (!ctx.getters.hasSharedResumeLoaded) {
          await ctx.dispatch('api-LOAD_SHARED_RESUME');
        }
      },

      createResume: async (ctx, newResume) => ctx.dispatch('api-CREATE_RESUME', newResume),

      updateResume: async (ctx, resume) => ctx.dispatch('api-UPDATE_RESUME', resume),

      deleteResume: async (ctx, resumeId) => ctx.dispatch('api-DELETE_RESUME', {
        resumeId,
      }),

    },
    mutations: {
      SET_INPUT_RESUME_SHARE_KEY: (state, resumeShareKey) => {
        state.inputResumeShareKey = resumeShareKey;
      },
      SET_CURRENT_RESUME_ID: (state, resumeId) => {
        state.currentResumeId = resumeId;
      },
      CLEAR_RESUMES: (state) => {
        state.resumes = null;
        state.resumesForUserId = null;
      },
    },
  }),
};
