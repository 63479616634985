<template lang='pug'>
#app
  deploy-notification(file-name='app-public.js')
  router-view
</template>

<script>
import faviconUrl from '@/assets/images/favicon/favicon.ico';

import '@/utils/init-table-component';

const components = {
  'deploy-notification': require('@/components/other/deploy-notification').default,
};

export default {
  components,
  metaInfo: {
    title: 'Corporate Matchmakers | A360', // default title
    titleTemplate: '%s | A360', // all titles use this template
    htmlAttrs: {
      lang: 'en',
      amp: true,
    },
    meta: [
      { charset: 'utf-8' },
      { name: 'viewport', content: 'width=device-width, initial-scale=1' },
      // meta description - override on all indexable pages
      { vmid: 'description', name: 'description', content: 'A360: Corporate Matchmakers' },
    ],
    link: [
      { rel: 'shortcut icon', type: 'image/ico', href: faviconUrl },
    ],
    ...process.env.AGENCY360SF_ENV === 'production' && {
      script: [
        // Keep this hash consistent with the font-kit
        { src: '//hello.myfonts.net/count/39de38', async: true },
      ],
    },
  },
  mounted() {
    document.dispatchEvent(new Event('prerender-ready'));
  },
};
</script>


<style lang='less'>
@import '~assets/style/core.less';

#app {
  height: 100%;
}
</style>
