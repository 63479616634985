<template lang='pug'>
#auth-settings
  h3 Login Methods

  todo-section(
    title='Email & Password'
    :theme='storeUser.hasPassword ? "green" : null'
    :icon='storeUser.hasPassword ? "check-circle" : "times-circle"'
  )
    template(slot='details')
      template(v-if='storeUser.hasPassword') You can log in with a password
      template(v-else) You do not have a password

    //- user has password - show update pass options
    template(v-if='storeUser.hasPassword')
      form-row
        form-input(type='container' label='Login Email')
          | {{ storeUser.email }}
          = ' - '
          router-link(:to='{ name: "profile" }') update your email here
      form-group(label='Update your password')
        form-row
          form-input(
            type='password' v-model='updatePasswordPayload.currentPassword'
            label='Current password' allow-show-password
            required
          )
          form-input(
            type='password' v-model='updatePasswordPayload.newPassword'
            label='New password' allow-show-password
            required check-password-strength
          )

        form-row
          error-message(:request-status='updatePasswordRequest')
          v-button(
            theme='transparent-dark'
            :request-status='updatePasswordRequest'
            @click='saveNewPasswordButtonHandler'
          ) Save New Password

    //- user does not have password - let them do a pass reset to
    template(v-else)
      form-row(no-inputs)
        p You currently do not have a password and you don't need one to log in using google.
        p If you want to be able to use a password for login, you can set one so by requesting a password reset below. You will receive an email with a link you can use to set a new password.
      form-row()

        v-button(
          v-if='passwordResetRequest.isSuccess'
          theme='transparent-dark'
          icon='check'
        ) Check your email for instructions

        v-button(
          v-else
          theme='transparent-dark'
          @click='requestPasswordResetButtonHandler'
          :request-status='passwordResetRequest'
          :icon='passwordResetRequest.isSuccess ? "check" : ""'
        ) Request password reset



  todo-section(
    title='Google Account'
    :theme='storeUser.googleAuthDetails.id ? "green" : null'
    :icon='!!storeUser.googleAuthDetails.id ? "check-circle" : "times-circle"'
  )
    template(slot='details')
      template(v-if='storeUser.googleAuthDetails.id') You can log in using your google account
      template(v-else) Your google account is not linked

    form-row
      form-input(type='container' label='Google Account')
        | {{ storeUser.googleAuthDetails.id ? storeUser.googleAuthDetails.label : "not linked"}}

      v-button(@click='connectButtonHandler(true)' inline icon='google-color-g' theme='white-with-border')
        | {{ storeUser.googleAuthDetails.id ? "Relink" : "Link" }} your Google Account


  oauth-connect(
    ref='oauthConnect'
    :provider-config='oauthProviderConfig'
    @error='oauthFailure' @code='oauthSuccess'
  )

</template>

<script>
import _ from 'lodash';
import { mapGetters } from 'vuex';

import { vuelidateGroupMixin } from '@/components/forms/vuelidate-group';
import { mapRequestStatuses } from '@/utils/vuex-api-utils';

const components = {
  'oauth-connect': require('@/components/other/oauth-connect').default,
};

export default {
  components,
  mixins: [vuelidateGroupMixin],
  data() {
    return {
      user: {},
      updatePasswordPayload: {},

    };
  },
  computed: {
    ...mapGetters('authUser/profile', {
      storeUser: 'user',
    }),
    ...mapRequestStatuses({
      updatePasswordRequest: 'authUser/profile/UPDATE_USER_PASSWORD',
      passwordResetRequest: 'auth/REQUEST_PASSWORD_RESET',
    }),
    ...mapGetters('auth', ['oauthProviderConfig']),
  },
  watch: {
    storeUser: {
      // deep: true,
      handler() { this.user = _.cloneDeep(this.storeUser); },
    },
  },
  beforeMount() {
    this.user = _.cloneDeep(this.storeUser);
  },
  methods: {
    async saveNewPasswordButtonHandler() {
      if (this.$hasError()) return;
      await this.$store.dispatchApiAction('authUser/profile/UPDATE_USER_PASSWORD', this.updatePasswordPayload);
      if (this.updatePasswordRequest.isSuccess) {
        this.updatePasswordPayload = {}; // clears the inputs
        this.$resetAll();
      }
    },


    async connectButtonHandler(withCalendarScopes) {
      this.$refs.oauthConnect.launch('google');
    },

    oauthFailure() {
    },
    oauthSuccess(payload) {
      // payload should have { provider, code } and any other info from oauth flow
      this.$store.dispatchApiAction('authUser/profile/CONNECT_CALENDAR_ACCOUNT', {
        ...payload,
        skipRefresh: true, // because we didn't add the calendar scopes needed to pull calendars
      });
    },

    requestPasswordResetButtonHandler() {
      this.$store.dispatchApiAction('auth/REQUEST_PASSWORD_RESET', { email: this.storeUser.email });
    },

  },
};
</script>

<style lang='less'>
</style>
