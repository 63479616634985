<template lang='pug'>
.share-resume-step
  h2 Share Resume
  p You can now share your resume with a special link, or you can download it directly.
  form-row
    form-input(type='container' label='Sharing Settings')
      p Your can share your resume directly using a special link. You can stop sharing your resume at any time.
      template(v-if='resumeIsShared')
        p Resume Link:
          span {{ ' ' }}
          a.resume-share-link(:href='resumeUrl' target='_blank') {{ resumeUrl }}
      v-button(
        v-if='resumeIsShared' icon='share-alt'
        theme='transparent-red'
        @click='disableShareResumeButtonHandler' confirm-click
      ) Stop Sharing My Resume
      v-button(
        v-else icon='share-alt' theme='transparent-blue'
        @click='enableShareResumeButtonHandler'
      ) Share My Resume

  //-- Download Resume
  form-row
    form-input(type='container' label='Download Resume')
      p You can download your resume to your computer as a PDF.
      v-button(
        icon='download'
        theme='transparent-blue'
        @click='downloadResumeButtonHandler'
      ) Download Resume

  popup(ref='downloadResumePopup' title='Download Resume')
    .download-resume-content-wrapper
      .download-resume-content(id='my-resume')
        resume-preview(:resume='effectiveResume')
    form-row
      .download-resume-message
        p Your resume will begin downloading shortly...
    form-row
      v-button(@click='closeDownloadResumeButtonHandler') Close

</template>

<script>
import _ from 'lodash';
import { mapGetters } from 'vuex';

import { vuelidateGroupMixin } from '@/components/forms/vuelidate-group';
import { mapRequestStatuses } from '@/utils/vuex-api-utils';

import * as html2canvas from 'html2canvas';
import * as JSPDF from 'jspdf';

const components = {
  'resume-preview': require('@/components/other/resume-preview').default,
};

const RESUME_ID = 'my-resume';

export default {
  components,
  mixins: [vuelidateGroupMixin],
  data() {
    return {
      resume: {},
    };
  },
  computed: {
    ...mapGetters('enums', [
      'skillOptions',
      'industryOptions',
      'languageOptions',
      'companySizeOptions',
      'countryOptions',
    ]),
    ...mapGetters('authUser/resumes', [
      'currentResume',
      'hasResumesLoaded',
      'resumeIsShared',
      'resumeUrl',
      'effectiveResume',
    ]),
  },
  watch: {
    storeResume: {
      // deep: true,
      handler() {
        this.resetToStoreState();
      },
    },
  },
  beforeMount() {
    this.resetToStoreState();
  },
  methods: {
    resetToStoreState() {
      this.resume = _.cloneDeep(this.storeResume);
    },

    async enableShareResumeButtonHandler() {
      await this.$store.dispatchApiAction(
        'authUser/resumes/TOGGLE_SHARE_RESUME',
        {
          shareToggle: true,
        },
      );
    },

    async disableShareResumeButtonHandler() {
      await this.$store.dispatchApiAction(
        'authUser/resumes/TOGGLE_SHARE_RESUME',
        {
          shareToggle: false,
        },
      );
    },

    downloadResumeButtonHandler() {
      this.$refs.downloadResumePopup.open();

      this.tryDownloadResume();
    },

    tryDownloadResume() {
      const targetHtml = document.querySelector(`#${RESUME_ID}`);

      if (targetHtml) {
        this.downloadResume();
      } else {
        setTimeout(() => {
          this.tryDownloadResume();
        }, 500);
      }
    },

    async downloadResume() {
      const filename = `${this.currentResume.name}-resume.pdf`;
      const targetHtml = document.querySelector(`#${RESUME_ID}`);

      if (targetHtml) {
        return html2canvas(targetHtml, {
          scale: 1.4,
          onclone(clonedDoc) {
            // Un-hide the element.
            clonedDoc.getElementById(RESUME_ID).style.visibility = 'visible';
          },
        }).then((canvas) => {
          const imageData = canvas.toDataURL('image/jpg');

          const pdf = new JSPDF('p', 'mm', 'a4');
          pdf.addImage(imageData, 'JPG', 0, 0, 211, 298);
          pdf.save(filename);
        });
      }

      return undefined;
    },

    closeDownloadResumeButtonHandler() {
      this.$refs.downloadResumePopup.close();
    },
  },
};
</script>

<style lang='less'>
.share-resume-step {
  .share-resume-link {
    overflow-wrap: anywhere;
    display: block;
    background: lightgray;
    color: blue;
    border-radius: 6px;
    padding: 6px;
  }

  .download-resume-content-wrapper {
    .download-resume-content {
      visibility: hidden; // Set to hide from user.
      left: 0;
      position: fixed;
      top: 0;
      width: 22cm; // ~width of printer paper.
    }
  }

  .download-resume-message {
    display: block;
    width: 100%;
    text-align: center;
  }
}
</style>
