<template lang='pug'>
//- TODO: v-if='value' is stopping some errors when the address being passed in is undefined
//- need to fix this and how we bind here...

//- TODO make required and requiredWarning work with a single error
//- message for the whole block

form-group(:label='label' :disabled='disabled' v-if='value')
  form-row
    form-input(
      type='text' v-model='value.line1'
      label='Line 1' placeholder='line 1' :no-label='compact'
      :required='required' :required-warning='requiredWarning' :required-message='requiredMessage'
    )
    form-input(type='text' label='Line 2' placeholder='line 2' v-model='value.line2' :no-label='compact')
  form-row
    form-input(
      type='dropdown' v-model='value.country'
      label='Country' placeholder='- select country -' :no-label='compact'
      :options='countryOptions'
      :required='required' :required-warning='requiredWarning' :required-message='requiredMessage'
    )
    form-input(
      v-if='stateOptionsForCountry(value.country)'
      type='dropdown' v-model='value.state'
      :label='stateLabel(value.country)' :no-label='compact'
      :placeholder='`- select ${stateLabel(value.country).toLowerCase()} -`'
      :disabled='!value.country'
      :options='stateOptionsForCountry(value.country)'
      :required='required' :required-warning='requiredWarning' :required-message='requiredMessage'
    )
    form-input(
      v-else
      type='text' v-model='value.state'
      :label='stateLabel(value.country)' :no-label='compact'
      :max-length='3' to-upper-case
      :required='required' :required-warning='requiredWarning' :required-message='requiredMessage'
    )
    form-input(
      type='text' v-model='value.city'
      label='City' :no-label='compact' placeholder='city'
      :required='required' :required-warning='requiredWarning' :required-message='requiredMessage'
    )
    form-input(
      type='text' v-model='value.postalCode'
      :label='postalCodeLabel(value.country)' :no-label='compact' :placeholder='postalCodeLabel(value.country)'
      :required='required' :required-warning='requiredWarning' :required-message='requiredMessage'
    )
  slot
</template>


<script>
import { mapGetters } from 'vuex';

import {
  stateOptionsForCountry, postalCodeLabel, stateLabel,
} from '@/utils/local';

// TODO: fix v-model handling
// https://github.com/vuejs/vue/issues/4373
// technically we shouldnt be changing the passed in object directly
// but keeping a local copy and calling `$emit('input')` on changes

export default {
  props: {
    label: {
      type: String,
      default: 'Address',
    },
    value: {
      type: Object, // passed in via v-model
      default: () => {},
    },
    compact: Boolean,
    required: Boolean,
    requiredWarning: Boolean,
    requiredMessage: String,
    disabled: Boolean,
  },
  computed: {
    ...mapGetters('enums', ['countryOptions']),
  },
  methods: {
    stateOptionsForCountry, postalCodeLabel, stateLabel,
  },
};
</script>

<style lang='less'>


</style>
