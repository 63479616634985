export function makeEffectiveResume(resume, user) {
  const firstName = resume.firstName || user.firstName;
  const lastName = resume.lastName || user.lastName;

  const email = resume.email || user.email;
  const jobTitle = resume.jobTitle || user.jobTitle;

  return {
    ...resume,
    email,
    firstName,
    lastName,
    jobTitle,
  };
}

export function makeResumeLinksArray(resume) {
  const links = [];

  if (resume.email) {
    links.push({
      text: resume.email,
      url: `mailto:${resume.email}`,
    });
  }

  if (resume.linkedinUrl) {
    links.push({
      text: resume.linkedinUrl,
      url: resume.linkedinUrl,
    });
  }

  if (resume.websiteUrl) {
    links.push({
      text: resume.websiteUrl,
      url: resume.websiteUrl,
    });
  }

  if (resume.twitterUsername) {
    links.push({
      text: `@${resume.twitterUsername}`,
      url: `https://twitter.com/${resume.twitterUsername}`,
    });
  }

  if (resume.instagramUsername) {
    links.push({
      text: `@${resume.instagramUsername}`,
      url: `https://instagram.com/${resume.instagramUsername}`,
    });
  }

  return links;
}
