/*
  Globally register the components that are used everywhere just for convenience

  TODO: figure out what to do about the less common ones (address input, hellosign, etc)
*/

import Vue from 'vue';

Vue.component('form-group', require('./forms/form-group').default);
Vue.component('form-input', require('./forms/form-input').default);
Vue.component('form-input-option', require('./forms/form-input-option').default);
Vue.component('form-row', require('./forms/form-row').default);
Vue.component('save-bar', require('./forms/save-bar').default);

Vue.component('error-message', require('./general/error-message').default);
Vue.component('icon', require('./general/icon').default);
Vue.component('popup', require('./general/popup').default);
Vue.component('todo-section', require('./general/todo-section').default);
Vue.component('status-indicator', require('./general/status-indicator').default);
Vue.component('v-button', require('./general/v-button').default);
Vue.component('markdown', require('./general/markdown').default);
