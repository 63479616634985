<template lang='pug'>
main-layout
  .legal-page-wrapper
    h1 Terms of Service
    //- If updating this page/update date, update the constant TERMS_AND_POLICY_UPDATED_AT in ENV file
    p.small.italic These Terms of Service were last updated on February 12, 2018.

    h3 1. Acceptance of the Terms of Service
    p
      | Thank you for visiting the <router-link to='/'>agency360sf.com</router-link> website (the “<strong>Site</strong>”), operated by Aardor, Inc., a Delaware corporation doing business as “Agency360SF” having a principal place of business at 101 Larkspur Landing Circle, Suite 228, Larkspur, California 94939 (“<strong>Agency360SF</strong>”, “<strong>we</strong>” or “<strong>us</strong>”, “<strong>our</strong>”). The following terms and conditions (these “<strong>Terms</strong>”), govern access to and use of the Site, including any content, functionality and services offered on or through the Site, by anyone who browses, crawls, scrapes, or in any other way accesses or uses the Site, including but not limited to as a guest, registered user, or third party contractor of Agency360SF (“<strong>you</strong>”, “<strong>your</strong>”). For clarity, use of the Site by third party contractors of Agency360SF will be governed by these Terms to the extent these Terms do not conflict with any separate, executed written agreement between Agency360SF and such third party contractor. In the event of any conflict between these Terms and the terms of an executed written agreement between Agency360SF and any customer or third party contractor of Agency360SF, the terms of the executed written agreement with such customer or the third party contractor will prevail solely to the extent of such conflict.
    p
      | Please read these Terms carefully before you start to use the Site. By using the Site, you accept and agree to be bound and abide by these Terms and our <router-link to='/privacy-policy'>Privacy Policy</router-link> incorporated herein by reference. If you do not want to agree to these Terms or the Privacy Policy, you must not access or use the Site.
    p
      | The Site is offered and available to individuals who are 13 years of age or older, and who reside in the United States or any of its territories or possessions. By using the Site, you represent and warrant that you meet all of the foregoing eligibility requirements. If you do not meet all of these requirements (i.e. if you are not at least 13 years of age or older or if you do not reside in the United States or any of its territories or possessions), you must not access, use or register to use the Site.


    h3 2. Changes to these Terms
    p
      | We may revise and update these Terms from time to time in our sole discretion. All changes are effective immediately when we post them, and apply to all access to and use of the Site thereafter. Your continued use of the Site following the posting of revised Terms means that you accept and agree to the changes. You are expected to check this page frequently so you are aware of any changes, as they are binding on you.

    h3 3. Accessing the Site and Account Security
    p
      | We reserve the right to withdraw or amend the Site, and any service or material we provide on the Site, in our sole discretion without notice. We will not be liable if for any reason all or any part of the Site is unavailable at any time or for any period. From time to time, we may restrict access to some parts of the Site, or the entire Site, to you.
    p
      | You are responsible for:
      ul
        li Making all arrangements necessary for you to have access to the Site
        li Ensuring that all persons who access the Site through your internet connection are aware of these Terms and comply with them.

    p
      | To access the Site or any part of the Site, or any of the resources the Site offers, you may be asked to provide certain registration details or other information. It is a condition of your use of the Site that all the information you provide on the Site is correct, current and complete. You agree that all information you provide to register with the Site or otherwise, including but not limited to through the use of any interactive features on the Site, is governed by our <router-link to='/privacy-policy'>Privacy Policy</router-link> and you consent to all actions we take with respect to your information consistent with our Privacy Policy. We have the right to modify or restrict your access to the Site or any part of the Site at any time in our sole discretion for any or no reason, including but not limited to if, in our opinion, you have violated any provision of these Terms.

    h3 4. Intellectual Property Rights
    p
      | The Site and its entire contents, features and functionality (including but not limited to all information, software, text, displays, images, video and audio, and the design, selection and arrangement thereof), are owned by us, our licensors or other providers of such material and are protected by United States and international copyright, trademark, patent, trade secret and other intellectual property or proprietary rights laws.
    p
      | These Terms permit you to use the Site for your personal, non-commercial use only. You must not reproduce, distribute, modify, create derivative works of, publicly display, publicly perform, republish, download, store or transmit any of the material on our Site, except as follows:
      ul
        li Your computer may temporarily store copies of such materials in its RAM (random access memory) incidental to your accessing and viewing those materials.
        li You may store files that are automatically cached by your Web browser for display enhancement purposes.
        li You may print or download one copy of a reasonable number of pages of the Site for your own personal, non-commercial use and not for further reproduction, publication or distribution.
        li If we provide desktop, mobile or other applications for download, you may download a single copy to your computer or mobile device solely for your own personal, non-commercial use, provided you agree to be bound by our end user license agreement for such applications.
        li If we provide any social media features with certain content, you may take such actions as are enabled by such features.
    p
      | You must not:
      ul
        li Modify copies of any materials from the Site.
        li Use any illustrations, photographs, video or audio sequences or any graphics separately from the accompanying text.
        li Delete or alter any copyright, trademark or other proprietary rights notices from copies of materials from the Site.
        li Access or use for any commercial purposes any part of the Site or any services or materials available through the Site.
    p
      | If you print, copy, modify, download or otherwise use or provide any other person with access to any part of the Site in breach of these Terms, your right to use the Site will cease immediately and you must, at our option, immediately return or destroy any copies of the materials you have made. No right, title or interest in or to the Site or any content on the Site is transferred to you, and all rights not expressly granted are reserved by us. Any use of the Site not expressly permitted by these Terms is a breach of these Terms and may violate copyright, trademark and other laws. If you wish to make any use of material on the Site other than as set out in this Section 4, please address your request to: <a href='mailto:info@agency360sf.com' target='_blank'>info@agency360sf.com</a> If you provide any input, comments or suggestions regarding the Site or Agency360SF’s business and technology more generally or the possible modification, correction, improvement or enhancement of the Site, then Customer grants to Agency360SF a non-exclusive, perpetual, irrevocable, worldwide, non-transferable (except to Agency360SF’s Affiliates), royalty-free license, with the right to sublicense, under applicable laws to use, publish, modify, and otherwise benefit from such feedback in any manner and via any media.

    h3 5. Trademarks
    p
      | The Aardor and Agency360SF name, the terms LeaderMatch, the Aardor logo, the Agency360SF logo and all related names, logos, designs and slogans and all product and service names, logos, designs and slogans are our trademarks or the trademarks of our affiliates or licensors. You must not use such marks without our prior written permission. All other names, logos, product and service names, designs and slogans on the Site are the trademarks of their respective owners.

    h3 6. Prohibited Uses
    p
      | You may use the Site only for lawful purposes and in accordance with these Terms. You agree not to use the Site:
      ul
        li In any way that violates any applicable federal, state, local or international law or regulation (including, without limitation, any laws regarding the export of data or software to and from the U.S. or other countries).
        li For the purpose of exploiting, harming or attempting to exploit or harm minors in any way by exposing them to inappropriate content, such as by asking for personally identifiable information or otherwise.
        li To send, knowingly receive, upload, download, use or re-use any material which does not comply with the Content Standards set out in these Terms.
        li To transmit, or procure the sending of, any advertising or promotional material without our prior written consent, including any “junk mail,” “chain letter” or “spam” or any other similar solicitation.
        li To impersonate or attempt to impersonate Agency360SF, a Agency360SF employee, another user or any other person or entity (including, without limitation, by using e-mail addresses, aliases or screen/user names associated with any of the foregoing).
        li To engage in any other conduct that restricts or inhibits anyone’s use or enjoyment of the Site, or which, as determined by us, may harm Agency360SF, or users of the Site or expose them to liability.

    p
      | Additionally, you agree not to:
      ul

        li Use the Site in any manner that could disable, overburden, damage, or impair the Site or interfere with any other party’s use of the Site, including their ability to engage in real time activities through the Site.
        li Use any robot, spider or other automatic device, process or means to access the Site for any purpose, including monitoring or copying any of the material on the Site.
        li Use any manual process to monitor or copy any of the material on the Site or for any other unauthorized purpose without our prior written consent.
        li Use any device, software or routine that interferes with the proper working of the Site.
        li Introduce to the Site or expose any of its users to any viruses, trojan horses, worms, logic bombs or other material which is malicious or technologically harmful.
        li Attempt to gain unauthorized access to, interfere with, damage or disrupt any parts of the Site, the server on which the Site is stored, or any server, computer or database connected to the Site.
        li Attack the Site via a denial-of-service attack or a distributed denial-of-service attack.
        li Otherwise attempt to interfere with the proper working of the Site.

    h3 7. Person Contributions
    p
      | The Site currently does not allow you and/or other users to post, submit, publish, display or transmit to other users or other persons (hereinafter, “<strong>post</strong>”) content or materials (collectively, “<strong>User Contributions</strong>”) on or through the Site. You may not post any User Contributions or any other content to the Site at this time and we may remove any User Contributions you post or attempt to post. Under no circumstances will we be responsible, or liable to any party (including you), for the content or accuracy of any User Contributions you or any other user post or attempt to post to the Site.

    h3 8. Monitoring and Enforcement; Termination
    p
      | We have no obligation to monitor the Site, including any materials that you or other third parties transmit or post on the Site.  You acknowledge and agree that we have the right (but not the obligation) to monitor the Site and to alter or remove any such materials, to disclose such materials and the circumstances surrounding their transmission to any third party in order to operate the Site properly, to protect ourselves, our sponsors and our members and visitors, and to comply with legal obligations or governmental requests.
    p
      | We have the right to:
      ul
        li Disclose your identity or other information about you to any third party if required or compelled by law, court order, or government action.
        li Take appropriate legal action, including without limitation, referral to law enforcement, for any illegal or unauthorized use of the Site.
        li Close any account you may have on the Site, or terminate or suspend your access to all or part of the Site for any or no reason in our sole discretion, including but not limited to in accordance with Section 16 (Term and Termination).
    p
      | Without limiting the foregoing, we have the right to fully cooperate with any law enforcement authorities or court order requesting or directing us to disclose the identity or other information of anyone accessing or otherwise using the Site. YOU WAIVE, AND HOLD US AND OUR AFFILIATES, LICENSEES AND SERVICE PROVIDERS HARMLESS FROM ANY CLAIMS RESULTING FROM ANY ACTION TAKEN BY ANY OF THE FOREGOING PARTIES DURING OR AS A RESULT OF ITS INVESTIGATIONS AND FROM ANY ACTIONS TAKEN AS A CONSEQUENCE OF INVESTIGATIONS BY ANY SUCH PARTIES OR LAW ENFORCEMENT AUTHORITIES.
    p
      | However, we cannot, and do not undertake to, review any material (including User Contributions) that may be posted on the Site, and cannot ensure prompt removal of objectionable material after it has been posted. Accordingly, we assume no liability for any action or inaction regarding transmissions, communications or content provided by any user or third party. We have no liability or responsibility to anyone for performance or nonperformance of the activities described in this section.

    h3 9. Content Standards
    p
      | As discussed in Section 7, the Site does not allow you or any other user to post content including User Contributions and you may not post or attempt to post any content on the Site, including content that:
      ul
        li Contains any material which is defamatory, obscene, indecent, abusive, offensive, harassing, violent, hateful, inflammatory or otherwise objectionable.
        li Promotes sexually explicit or pornographic material, violence, or discrimination based on race, sex, religion, nationality, disability, sexual orientation or age.
        li Infringes any patent, trademark, trade secret, copyright or other intellectual property or other rights of any other person.
        li Violates the legal rights (including the rights of publicity and privacy) of others or contains any material that could give rise to any civil or criminal liability under applicable laws or regulations or that otherwise may be in conflict with these Terms and our <router-link to='/privacy-policy'>Privacy Policy</router-link>.
        li Is likely to deceive any person.
        li Promotes any illegal activity, or advocates, promotes or assists any unlawful act.
        li Causes annoyance, inconvenience or needless anxiety or is likely to upset, embarrass, alarm or annoy any other person.
        li Impersonates any person, or misrepresents your identity or affiliation with any person or organization.
        li Involves, promotes, or sells any commercial activities other than the goods and/or services offered on or through the Site, exclusively as offered on or through the Site, on behalf of yourself or any third party, including through advertising, referrals, barter, or the direct or indirect solicitation of customers, or by offering or promoting commercial activities or sales, such as contests, sweepstakes and other sales promotions.
        li Gives the impression that it emanates from or is endorsed by us without our prior written consent.
        li Gives the impression that it emanates from or is endorsed by any other person or entity if this is not the case.

    h3 10. Copyright Infringement
    p
      | We take claims of copyright infringement seriously. We will respond to notices of alleged copyright infringement that comply with applicable law. If you believe any materials accessible on or from the Site infringe your copyright, you may request removal of those materials (or access to them) from the Site by submitting written notification to us pursuant to Section 25 below.

    h3 11. Reliance on Information Posted
    p
      | The information presented on or through the Site is made available solely for general information purposes. We do not warrant the accuracy, completeness or usefulness of this information. Any reliance you place on such information is strictly at your own risk. We disclaim all liability and responsibility arising from any reliance placed on such materials by you or any other visitor to the Site, or by anyone who may be informed of any of its contents.
    p
      | The Site may include content provided by third parties, including materials provided by individuals, bloggers and third-party licensors, syndicators, aggregators and/or reporting services. All statements and/or opinions expressed in these materials, and all articles and responses to questions and other content, other than the content provided by us, are solely the opinions and the responsibility of the person or entity providing those materials. These materials do not necessarily reflect our opinion. We are not responsible, or liable to you or any third party, for the content or accuracy of any materials provided by any third parties.

    h3 12. Changes to the Site
    p
      | We may update the content on the Site from time to time, but its content is not necessarily complete or up-to-date. Any of the material on the Site may be out of date at any given time, and we are under no obligation to update such material.

    h3 13. Information About You and Your Visits to the Site
    p
      | All information we collect on the Site is subject to our <router-link to='/privacy-policy'>Privacy Policy</router-link>. By using the Site, you consent to all actions taken by us with respect to your information in compliance with the Privacy Policy.

    h3 14. Links from the Site
    p
      | If the Site contains links to other sites and resources provided by third parties, these links are provided for your convenience only. This includes links contained in advertisements, including banner advertisements and sponsored links. We have no control over the contents of those sites or resources, and accept no responsibility for them or for any loss or damage that may arise from your use of them. If you decide to access any of the third party websites linked to the Site, you do so entirely at your own risk and subject to the terms and conditions of use for such websites.

    h3 15. Geographic Restrictions
    p
      | The owner of the Site is based in the state of California in the United States. We provide the Site for use only by persons who reside in the United States. We make no claims that the Site or any of its content is accessible or appropriate outside of the United States. Access to the Site may not be legal by certain persons or in certain countries. If you access the Site from outside the United States, you do so on your own initiative and are responsible for compliance with local laws.

    h3 16. Term and Termination
    p
      | We may suspend your ability to use certain portions of the Site, and/or ban you altogether from the Site for any or no reason, including without limitation, any violation of these Terms, and without notice or liability of any kind. Any such action could prevent you from accessing the Site or any other related information.
    p
      | You may terminate the Terms at any time by discontinuing your use of and access to the Site and providing us with a notice of your termination in writing at the address provided in Section 25 (Your Comments and Concerns).
    p
      | In the event of any termination of these Terms, Sections 4, 5, 8, 10, 11, 13, 14, 16, 17, 18, 19, 21, 22, 23, and 24 shall survive in full force and effect.


    h3 17. Disclaimers
    p
      | PLEASE READ THIS SECTION CAREFULLY SINCE IT LIMITS OUR LIABILITY TO YOU. EACH OF THE SUBSECTIONS BELOW ONLY APPLIES UP TO THE MAXIMUM EXTENT PERMITTED UNDER APPLICABLE LAW. NOTHING HEREIN IS INTENDED TO LIMIT ANY RIGHTS YOU MAY HAVE WHICH MAY NOT BE LAWFULLY LIMITED. IF YOU ARE UNSURE ABOUT THIS OR ANY OTHER SECTION OF THESE TERMS, PLEASE CONSULT WITH A LEGAL PROFESSIONAL PRIOR TO ACCESSING OR USING THE SITE. BY ACCESSING OR USING THE SITE, YOU REPRESENT THAT YOU HAVE READ, UNDERSTOOD, AND AGREE TO THESE TERMS, INCLUDING THIS SECTION. YOU ARE GIVING UP SUBSTANTIAL LEGAL RIGHTS BY AGREEING TO THESE TERMS.
    p
      | YOU UNDERSTAND THAT WE CANNOT AND DO NOT GUARANTEE OR WARRANT THAT THE SITE OR ANY FILES AVAILABLE FOR DOWNLOADING FROM THE INTERNET OR THE SITE WILL BE FREE OF VIRUSES OR OTHER DESTRUCTIVE CODE. YOU ARE RESPONSIBLE FOR IMPLEMENTING SUFFICIENT PROCEDURES AND CHECKPOINTS TO SATISFY YOUR PARTICULAR REQUIREMENTS FOR ANTI-VIRUS PROTECTION AND ACCURACY OF DATA INPUT AND OUTPUT AND FOR MAINTAINING A MEANS EXTERNAL TO OUR SITE FOR ANY RECONSTRUCTION OF ANY LOST DATA. WE WILL NOT BE LIABLE FOR ANY LOSS OR DAMAGE CAUSED BY A DISTRIBUTED DENIAL-OF-SERVICE ATTACK, VIRUSES OR OTHER TECHNOLOGICALLY HARMFUL MATERIAL THAT MAY INFECT YOUR COMPUTER EQUIPMENT, COMPUTER PROGRAMS, DATA OR OTHER PROPRIETARY MATERIAL DUE TO YOUR USE OF THE SITE OR ANY SERVICES OR ITEMS OBTAINED THROUGH THE SITE OR TO YOUR DOWNLOADING OF ANY MATERIAL POSTED ON IT, OR ON ANY WEBSITE LINKED TO IT.
    p
      | YOU EXPRESSLY ACKNOWLEDGE AND AGREE THAT YOUR USE OF THE SITE, ITS CONTENT AND ANY SERVICES OR ITEMS OBTAINED THROUGH THE SITE IS AT YOUR OWN RISK. TO THE MAXIMUM EXTENT PERMITTED BY APPLICABLE LAW, THE SITE, ITS CONTENT AND ANY SERVICES OR ITEMS OBTAINED THROUGH THE SITE ARE PROVIDED ON AN “AS IS” AND “AS AVAILABLE” BASIS, WITH ALL FAULTS, AND WITHOUT ANY WARRANTIES OF ANY KIND, EITHER EXPRESS OR IMPLIED. NEITHER LEADEREQ NOR ANY PERSON ASSOCIATED WITH LEADEREQ MAKES ANY WARRANTY OR REPRESENTATION WITH RESPECT TO THE COMPLETENESS, SECURITY, RELIABILITY, QUALITY, ACCURACY OR AVAILABILITY OF THE SITE. WITHOUT LIMITING THE FOREGOING, NEITHER LEADEREQ NOR ANYONE ASSOCIATED WITH LEADEREQ REPRESENTS OR WARRANTS THAT THE SITE, ITS CONTENT OR ANY SERVICES OR ITEMS OBTAINED THROUGH THE SITE WILL BE ACCURATE, RELIABLE, ERROR-FREE, UNINTERRUPTED, OR SECURE, THAT DEFECTS WILL BE CORRECTED, THAT THE SITE OR THE SERVER(S) THAT MAKES IT AVAILABLE ARE FREE OF VIRUSES OR OTHER HARMFUL COMPONENTS OR THAT THE SITE OR ANY SERVICES OR ITEMS OBTAINED THROUGH THE SITE WILL OTHERWISE MEET YOUR NEEDS OR EXPECTATIONS.
    p
      | WE MAKE NO AND HEREBY DISCLAIM ALL WARRANTIES OF ANY KIND, WHETHER EXPRESS OR IMPLIED, STATUTORY OR OTHERWISE, INCLUDING BUT NOT LIMITED TO ANY WARRANTIES OF MERCHANTABILITY, NON-INFRINGEMENT AND FITNESS FOR PARTICULAR PURPOSE. NO OPINION, ADVICE OR STATEMENT OF LEADEREQ OR ITS AFFILIATES, LICENSORS, SERVICE PROVIDERS, USERS, MEMBERS, OR VISITORS, WHETHER MADE ON THE SITE OR OTHERWISE, SHALL CREATE ANY WARRANTY. YOUR USE OF THE SITE AND ANY MATERIALS PROVIDED THROUGH THE SITE ARE ENTIRELY AT YOUR OWN RISK.

    h3 18. Limitation of Liability
    p
      | To the maximum extent permitted by applicable law, IN NO EVENT WILL WE, OUR AFFILIATES OR OUR OR THEIR LICENSORS, SERVICE PROVIDERS, OFFICERS, DIRECTORS, EMPLOYEES, OR AGENTS, BE LIABLE FOR DAMAGES OF ANY KIND, UNDER ANY LEGAL THEORY, ARISING OUT OF OR IN CONNECTION WITH YOUR USE, OR INABILITY TO USE, THE SITE, ANY WEBSITES LINKED TO IT, ANY CONTENT ON THE SITE OR SUCH OTHER WEBSITES OR ANY SERVICES OR ITEMS OBTAINED THROUGH THE SITE OR SUCH OTHER WEBSITES, INCLUDING ANY DIRECT, INDIRECT, SPECIAL, INCIDENTAL, CONSEQUENTIAL, EXEMPLARY, PUNITIVE, OR OTHER DAMAGES, INCLUDING BUT NOT LIMITED TO, PERSONAL INJURY, PAIN AND SUFFERING, EMOTIONAL DISTRESS, LOSS OF REVENUE, LOSS OF PROFITS, LOSS OF BUSINESS OR ANTICIPATED SAVINGS, LOSS OF USE, LOSS OF GOODWILL, LOSS OF DATA, AND WHETHER CAUSED BY TORT (INCLUDING NEGLIGENCE), BREACH OF CONTRACT OR OTHERWISE, EVEN IF FORESEEABLE. YOUR SOLE REMEDY FOR DISSATISFACTION WITH THE SITE, SERVICES, MATERIALS, RELATED SOFTWARE OR ANY LINKED SITE IS TO STOP USING THE SITE, SERVICES, MATERIALS, RELATED SOFTWARE OR LINKED SITE, AS APPLICABLE. To the maximum extent permitted by applicable law, THE SOLE AND EXCLUSIVE MAXIMUM LIABILITY TO COMPANY FOR ALL DAMAGES, LOSSES AND CAUSES OF ACTION, WHETHER IN CONTRACT, TORT (INCLUDING, WITHOUT LIMITATION, NEGLIGENCE) OR OTHERWISE, SHALL BE TOTAL AMOUNT PAID BY YOU, IF ANY, TO ACCESS THE SITE. The foregoing limitations will apply even if the above stated remedy fails of its essential purpose.
    p
      | If you have a dispute with one or more users, you release us, our affiliates, officers, directors, employees, and agents from claims, demands and damages (actual and consequential) of every kind and nature, known and unknown, arising out of or in any way connected with such disputes.
    p
      | If you are a California resident, you may specifically waive California Civil Code Section 1542, which says “A general release does not extend to claims which the creditor does not know or suspect to exist in his favor at the time of executing the release, which if known by him must have materially affected his settlement with the debtor.”

    h3 19. Indemnification
    p
      | You agree to defend, indemnify and hold us, our affiliates, licensors and service providers, and our and their respective officers, directors, employees, contractors, agents, licensors, suppliers, successors and assigns from and against any claims, liabilities, damages, judgments, awards, losses, costs, expenses or fees (including reasonable attorneys’ fees) arising out of or relating to your violation of these Terms or your use of the Site, including, but not limited to, your User Contributions, any use of the Site’s content, services and products other than as expressly authorized in these Terms or your use of any information obtained from the Site.

    h3 20. Notice to California Users
    p
      | Under California Civil Code Section 1789.3, California Site users may be entitled to the following specific consumer rights notice: The Complaint Assistance Unit of the Division of Consumer Services of the California Department of Consumer Affairs may be contacted in writing at 1625 North Market Blvd., Suite N 112, Sacramento, California 95834, or by telephone at (916) 445-1254 or (800) 952-5210.

    h3 21. Governing Law and Jurisdiction
    p
      | All matters relating to the Site and these Terms and any dispute or claim arising therefrom or related thereto (in each case, including non-contractual disputes or claims), shall be governed by and construed in accordance with the internal laws of the State of California without giving effect to any choice or conflict of law provision or rule (whether of the State of California or any other jurisdiction).
    p
      | Any legal suit, action or proceeding arising out of, or related to, these Terms or the Site shall be instituted exclusively in the federal courts of the United States or the courts of the State of California in each case located in the City and County of San Francisco, although we retain the right to bring any suit, action or proceeding against you for breach of these Terms in any other relevant country. You waive any and all objections to the exercise of jurisdiction over you by such courts and to venue in such courts.

    h3 22. Limitation on Time to File Claims
    p
      | ANY CAUSE OF ACTION OR CLAIM YOU MAY HAVE ARISING OUT OF OR RELATING TO THESE TERMS OF USE OR THE SITE MUST BE COMMENCED WITHIN ONE (1) YEAR AFTER THE CAUSE OF ACTION ACCRUES, OTHERWISE, SUCH CAUSE OF ACTION OR CLAIM IS PERMANENTLY BARRED.

    h3 23. Waiver and Severability
    p
      | No waiver by us of any term or condition set forth in these Terms shall be deemed a further or continuing waiver of such term or condition or a waiver of any other term or condition, and any failure by us to assert a right or provision under these Terms shall not constitute a waiver of such right or provision.
    p
      | If any provision of these Terms is held by a court or other tribunal of competent jurisdiction to be invalid, illegal or unenforceable for any reason, such provision shall be eliminated or limited to the minimum extent such that the remaining provisions of these Terms will continue in full force and effect.

    h3 24. Entire Agreement
    p
      | These Terms and our <router-link to='/privacy-policy'>Privacy Policy</router-link>, constitute the sole and entire agreement between you and Aardor, Inc. with respect to the Site and supersede all prior and contemporaneous understandings, agreements, representations and warranties, both written and oral, with respect to the Site, but for any previously or subsequently executed agreements between Aardor Inc. and any customers or third party contractors entered into pursuant to Section 1.

    h3 25. Your Comments and Concerns
    p The Site is operated by Aardor, Inc., 101 Larkspur Landing Circle, Suite 228, Larkspur California, 94939.
    p All other feedback, comments, requests for technical support and other communications relating to the Site should be directed to: <a href='mailto:info@agency360sf.com' target='_blank'>info@agency360sf.com</a>
    p Thank you for visiting the Site.

</template>

<script>
import { makeSeoRefs } from '@/utils/meta';

const components = {
  'main-layout': require('@/app-public/common/layout/main-layout').default,
};

export default {
  components,
  metaInfo: {
    title: 'Terms of Service',
    link: makeSeoRefs('terms-of-service'),
  },
};
</script>

<style lang='less'>
.legal-page-wrapper {
  h3 {
    margin-top: 50px;
  }
  ul {
    padding-left: 30px;
  }
}
</style>
