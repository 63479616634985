<template lang='pug'>
.main-layout(:class='classes')
  .flex-wrap
    header.header-bar
      .popup-mask
      router-link.main-logo(
        :to='{name: "home"}'
      )
        logo-svg

      template(v-if='!noNav')
        nav.primary-nav(@click='handleNavClick')
          //- LOGGED IN NAV
          ul(v-if='userIsLoggedIn')
            li
              router-link(:to='{name: "resume-list"}')
                | My Resumes      
            li
              router-link.account-link(:to='{name: "profile"}')
                .account-link__user-name
                  .account-link__user-name__loading(v-if='!userDataIsLoaded') Loading...
                  template(v-else)
                    .account-link__user-name__name {{ user.firstName }}
              ul
                li: router-link(:to='{name: "profile"}') My Profile
                li: router-link(:to='{name: "account"}' ) Account Settings
                li: a(href='#' @click.prevent='$refs.logoutPopup.open()' ) Log Out

          //- LOGGED OUT NAV
          ul(v-else)
            li: a(href='https://www.agency360sf.com/' target="_blank") Executive Search
            li: router-link(:to='{name: "login"}') Login
            li: v-button(to-named-route='resume-signup' theme='pink') Build Your Free Resume

        a.mobile-nav-button.hamburger.hamburger--squeeze(
          href='#' @click.prevent='toggleMobileNav'
          :class='isMobileNavOpen ? "is-active" : ""'
        )
          span.hamburger-box
            span.hamburger-inner

    .main-content
      slot

  site-footer(v-if='!noFooter')
  //- policy-updated-popup
  //- api-error-popup
  popup(
    ref='logoutPopup'
    title='Goodbye'
  )
    form-row(no-inputs)
      | We hope to see you again soon!
    form-row
      form-input(
        type='checkbox' v-model='logoutAllDevices'
        label='By default, you will only log out of this session'
      )
        | Check this box to log out all sessions and devices
    form-row
      form-input(type='container' no-label)
        v-button(
          @click='logoutButtonHandler'
          :request-status='logoutRequest' loading-text='Logging you out...'
          theme='red'
        ) Log me out

</template>

<script>
import { mapGetters } from 'vuex';
import { mapRequestStatuses } from '@/utils/vuex-api-utils';

const components = {
  'site-footer': require('./site-footer').default,
  'logo-svg': require('!vue-svg-loader!@/assets/images/logos/logo.svg').default,
};

export default {
  components,
  metaInfo() {
    return {
      style: [{ type: 'text/css', cssText: this.lockBodyStyles }],
    };
  },
  data() {
    return {
      logoutAllDevices: false,
    };
  },
  props: {
    fullWidth: Boolean,
    noFixedHeader: Boolean,
    noFooter: Boolean,
    noNav: Boolean,
  },
  computed: {
    ...mapGetters('auth', ['userIsLoggedIn']),
    ...mapGetters('window', ['isMobileNavOpen']),
    ...mapGetters('authUser/profile', ['user', 'userDataIsLoaded', 'userEvaluationsEnabled']),
    ...mapRequestStatuses({
      getUserRequest: 'authUser/profile/GET_USER',
      logoutRequest: 'auth/LOGOUT',
    }),
    classes() {
      return {
        'main-layout--full-width': this.fullWidth,
        'main-layout--no-fixed-header': this.noFixedHeader,
        'main-layout--no-footer': this.noFooter,
        'main-layout--mobile-nav-open': this.isMobileNavOpen,
      };
    },
    currentRouteRequiresAuth() {
      // have to check the current route and parent routes if it requires auth
      return this.$router.currentRoute.matched.some((route) => route.meta.requiresAuth);
    },

    lockBodyStyles() {
      if (!this.isMobileNavOpen) return '';
      return `
        body {
          overflow: hidden;
        }
      `;
    },
  },
  mounted() {
    // a new "main-layout" is mounted on each page load, so we can fire this here
    // alert('new route mounted');
    this.$store.dispatch('window/setMobileNavOpen', false);
  },
  methods: {
    toggleMobileNav() {
      this.$store.dispatch('window/toggleNavOpen');
    },
    async logoutButtonHandler() {
      if (this.logoutAllDevices) {
        await this.$store.dispatchApiAction('auth/LOGOUT');
      }
      this.$router.push({ name: 'logout' });
      this.$refs.logoutPopup.close();
    },
    handleNavClick(e) {
      // closing the nav is handled on mounted() above which basically catches a page change
      // because this component unmounts and a new one takes over when navigating to a new route
      // BUT if user clicks the link for the currently open page nothing happens :(
      // so here we watch for a click on any "A" tag in the nav and toggle the nav closed
      if (e.target.nodeName === 'A') {
        this.$store.dispatch('window/setMobileNavOpen', false);
      }
    },
  },
};
</script>

<style lang='less'>
// this flex stuff sets up the sticky footer
.main-layout {
  min-height: 100%;
  display: flex;
  flex-direction: column;

  > .flex-wrap {
    flex: 1 0 auto;
  }
  .main-content {
    max-width: 1000px;
    margin: 0 auto;
    padding: 15px;
    padding-top: @header-bar-height--desktop;
    padding-bottom:0px;

    @media @mq-small-only {
      padding-top: @header-bar-height--mobile;
    }
  }


  &.main-layout--full-width .main-content {
    max-width: 100%;
    padding-left: 0;
    padding-right: 0;
  }

}

.header-bar {
  display: flex;
  align-items: center;
  background: white;
  height: @header-bar-height--desktop;
  padding: 0px;
  color: @navy;
  text-align: left;
  position: fixed;
  z-index: 1000;
  width: 100%;

  @media @mq-small-only {
    height: @header-bar-height--mobile;
  }
}

.main-logo {
  height: 40px;
  margin-left: 12px;
  width: auto;
  position: relative;
  z-index: 2;

  @media @mq-small-only {
    height: 36px;
    margin-left: 15+px;
  }

  > svg {
    display: block;
    height: 100%;

    .st1 {
      fill: @brand-pink;
      transition: 0.3s all;
    }
  }

  &:hover {
    > svg {
      // highlight the Agency360SF text
      .st1 { 
        opacity: .8;
       }
    }
  }
}


// big fancy nav that works for both desktop and mobile
// on desktop it shows dropdowns and on mobile its a full slide-in pane
.primary-nav {
  margin-left: 100%;

  // .icon { margin-right: 6px; }

  // DESKTOP / LARGE NAV -- dropdowns from header bar
  @media @mq-medium {
    height: inherit;
    flex-grow: 1;
    // padding-left: 30px;
    display: flex;
    justify-content: flex-end; // align right
    margin-left: 0;

    > ul { // primary nav
      margin: 0;
      list-style: none;
      padding-left: 0;
      display: flex;

      > li { // first level nav items
        margin-left: 5px;
        position: relative;
        height: 100%;
        display: flex;

        &.visible-small-only {
          display: none;
        }

        > ul { // second level nav
          display: none;
          list-style: none;
          padding-left: 0;
          position: absolute;
          top: 100%;
          margin-left: 0px;
          box-shadow: 0px 8px 10px rgba(0,0,0,.1);
          min-width: 100%;
          width: auto;

          > li { // second level nav items
            background: white;
            padding: 0px 0px;
            position: relative;

            &:before { // top gray border
              content: '';
              display: block;
              position: absolute;
              top: 0;
              height: 1px;
              left: 10px;
              right: 10px;
              background: #EAEAEA;
            }


            a {
              // display: block;
              // padding-top: 16px;
              height: 44px;

              &:before { // extra ">"
                content: '>';
                display: inline-block;
                margin-right: 6px;
              }
            }
          }
        }
        &:hover {
          > ul { display: block; }
        }
      }
    }
    a {
      // color: darken(@logo-gray, 10%);
      display: inline-block;
      height: 100%;
      // text-transform: uppercase;
      font-size: 14px;
      line-height: 14px;
      font-weight: bold;
      padding: 0 8px;
      white-space: nowrap;
      display: flex;
      align-items: center; // vertical centering
      padding-top: 4px; // text is slightly top aligned

      a {
        padding: 0;
      }

      &.router-link-exact-active, &.menu-link-active {
        color: @brand-pink;
      }
      
      &.button {
        padding-top: 18px;
        padding-bottom: 14px;
        padding-left: 20px;
        padding-right: 20px;
        margin: 8px 10px;
        height: auto;
        &.router-link-exact-active {
          color: white; // undo color change for active link
        }
      }
    }
  }

  // MOBILE/SMALL NAV -- toggled by burger button, slides in from right
  @media @mq-small-only {
    // display: none;
    position: fixed;
    width: 100%;
    top: @header-bar-height--mobile;
    bottom: 0;
    z-index: 1;
    background: white;
    overflow: auto;

    // color: white;

    // opacity: 0;
    transition: .25s all;

    .main-layout--mobile-nav-open & {
      opacity: 1;
      transform: translate3d(-100%,0,0);
    }

    a {
      // color: white;
      position: relative;
      display: block;
      padding: 20px 15px 16px;
      font-size: 14px;
      line-height: 14px;
      font-weight: bold;
      &.router-link-exact-active {
        color: @brand-pink;
        font-weight: bold;
      }
      &.button {
        margin: 15px;
        padding: 15px 0 11px;
      }
      &:after { // top gray border
        content: '';
        display: block;
        position: absolute;
        top: 0;
        height: 1px;
        left: 10px;
        right: 10px;
        background: rgba(0,0,0,.1);
      }
    }
    ul {
      list-style: none;
      padding: 0;
      margin: 0;
    }
    li {
      width: 100%;
      // background: blue;
      // background: blue;
    }
    > ul > li > ul > li {
      // background: red;
      // padding-left: 10px;
      > a {
        &:before { // extra ">"
          content: '>';
          display: inline-block;
          margin-right: 6px;
          margin-left: 10px;
        }
      }

    }
  }

  .account-link {
    display: flex;
    align-items: center;
    padding: 5px 15px;
    // background: red;
    min-width: 210px;
    max-width: 270px;
    font-size: 14px;
    line-height: 18px;
    text-transform: unset; // rest of the nav is in caps
    font-weight: unset; // rest of nav is bold
    // margin-right: 0;
    // margin-left: 0;
    // border-left: 1px solid #F0F0F0;

    @media @mq-small-only {
      width: 100%;
      max-width: unset;
    }

    .profile-photo {
      margin-right: 10px;
    }
    .account-link__user-name {
      overflow: hidden;
      flex: 1 0 0;
    }
    .account-link__user-name__loading {
      font-style: italic;
      opacity: .7;
    }
    .account-link__user-name__name {
      padding-top: 4px;
      font-weight: bold;
    }
    .account-link__user-name__company {
    }
    .account-link__user-name__name, .account-link__user-name__company {
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
    }

  }


}

.mobile-nav-button { // NAV BURGER / X
  position: absolute;
  right: 0;
  width: @header-bar-height--mobile;
  height: @header-bar-height--mobile;
  z-index: 2;
  // color: white;
  // transition: background-color .5s;
  border: none;
  outline: none;
  display: flex;
  justify-content: center;
  align-items: center;
  color: @logo-gray;

  // have to undo some hover/active state css from the nav links
  &:hover, &:focus { color: @logo-gray; }
  &.is-active, &.is-active:hover, &.is-active:focus { color: @navy; }
  &:hover {
    cursor: pointer;
  }

  @media @mq-medium {
    display: none;
  }
}

// adapted from https://github.com/jonsuh/hamburgers
.hamburger {
  .hamburger-box {
    width: 26px;
    @media @mq-small-only {
      width: 26px;
    }
    height: 45px;
    display: block;
    position: relative;
  }
  .hamburger-inner {
    display: block;
    top: 50%;
    margin-top: 0px;
  }
  .hamburger-inner, .hamburger-inner::before, .hamburger-inner::after {
    width: inherit;
    height: 2px;
    background-color: currentColor;
    border-radius: 1px;
    position: absolute;
    transition: transform .15s ease;
  }
  .hamburger-inner::before, .hamburger-inner::after {
    content: "";
    display: block;
  }
  .hamburger-inner::before { top: -10px; }
  .hamburger-inner::after { bottom: -10px; }
  /*
    * Squeeze
    */
  &.hamburger--squeeze {
    .hamburger-inner {
      transition-duration: 0.075s;
      transition-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
    }
    .hamburger-inner::before {
      transition: top 0.075s 0.12s ease, opacity 0.075s ease;
    }
    .hamburger-inner::after {
      transition: bottom 0.075s 0.12s ease, transform 0.075s cubic-bezier(0.55, 0.055, 0.675, 0.19)
    }
    &.is-active { // turn it into an "X"
      .hamburger-inner {
        transform: rotate(45deg);
        transition-delay: 0.12s;
        transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
      }
      .hamburger-inner::before {
        top: 0;
        opacity: 0;
        transition: top 0.075s ease, opacity 0.075s 0.12s ease;
      }
      .hamburger-inner::after {
        bottom: 0;
        transform: rotate(-90deg);
        transition: bottom 0.075s ease, transform 0.075s 0.12s cubic-bezier(0.215, 0.61, 0.355, 1);
      }
    }
  }
}
</style>
