<template lang='pug'>
main-layout#page-signup
  template(v-if='signupRequest.isSuccess')
    h2 Sign up success!
    p redirecting...
  template(v-else-if='getEnumsRequest.isPending')
    h2 loading...
  template(v-else)
    h1 Build Your Free Resume
    p
      icon(name='exclamation-circle')
      |  Already have an account?
      =' '
      router-link(:to='{name: "login"}') Sign in here!

    form-row
      form-input(
        v-model='newUser.name'
        label='What is your name?'
        required
      )

    form-row
      form-input(
        v-model='newUser.email' 
        type='email' 
        label='What is your email address?' 
        required)

    form-row
      form-input(
        v-model='newUser.industry' type='dropdown'
        label='What industry are you in?'
        :options='industryOptions'
        required
      )
      form-input(v-if='newUser.industry === "other"' v-model='newUser.industryOther' label='Industry' required)
      
    form-row
      form-input(
        v-model='newUser.jobTitle'
        label='What is your current job title?'
      )

    form-row
      form-input(
        v-model='newUser.roleOption' type='dropdown'
        label='What kind of role are you looking for?'
        :options='roleOptions'
        required
      )

    form-row
      form-input(
        v-model='newUser.speakOption' type='dropdown'
        label='Would you like to speak with a career expert?'
        :options='careerSpeakOptions'
        required
      )
      form-input(v-if='newUser.speakOption === "yesPhone"' v-model='newUser.phone' type='phone' label='Phone' required)

    form-row
      v-button(
        @click='applyButtonHandler'
        :disabled='$vv.$error'
        :request-status='signupRequest' loading-text='Submitting your info...'
      ) Start My Resume
      error-message(:request-status='signupRequest')

</template>

<script>
import { makeSeoRefs } from '@/utils/meta';

import _ from 'lodash';
import { mapGetters } from 'vuex';

import { vuelidateGroupMixin } from '@/components/forms/vuelidate-group';
import { mapRequestStatuses } from '@/utils/vuex-api-utils';

import {
  SELF_REPORTED_SOURCE_OPTIONS,
  SELF_REPORTED_SOURCE_DETAIL_PROMPTS,
} from '@/data/self-reported-source';
import { BEST_CONTACT_TIMES } from '@/data/best-contact-times';

const components = {
  'main-layout': require('@/app-public/common/layout/main-layout').default,
};

export default {
  components,
  metaInfo: {
    title: 'Enroll Your Company',
    link: makeSeoRefs('company-signup'),
  },
  mixins: [vuelidateGroupMixin],
  data() {
    return {
      newUser: {},
    };
  },
  computed: {
    ...mapGetters('enums', [
      'industryOptions',
      'roleOptions',
      'careerSpeakOptions',
    ]),
    ...mapGetters('auth', ['userIsLoggedIn']),
    ...mapRequestStatuses({
      signupRequest: 'auth/RESUME_SIGNUP',
      getEnumsRequest: 'enums/GET_ENUMS',
    }),
    SELF_REPORTED_SOURCE_OPTIONS() {
      return SELF_REPORTED_SOURCE_OPTIONS;
    },
    selfReportedSourceDetailLabel() {
      return SELF_REPORTED_SOURCE_DETAIL_PROMPTS[
        this.newUser.selfReportedSource
      ];
    },
    contactTimeOptions: () => BEST_CONTACT_TIMES,
  },
  methods: {
    async applyButtonHandler() {
      if (this.$hasError()) return;

      await this.$store.dispatchApiAction('auth/RESUME_SIGNUP', {
        ...this.newUser,
      });

      if (this.signupRequest.isSuccess) {
        this.$router.push({ name: 'resume-list' });
      }
    },
  },
  beforeMount() {
    if (!this.getEnumsRequest.isSuccess) {
      this.$store.dispatchApiAction('enums/GET_ENUMS');
    }
  },
  mounted() {
    // redirect to resumes automatically.
    if (this.userIsLoggedIn) {
      this.$router.push({ name: 'resume-list' });
    }
  },
};
</script>

<style lang='less'>
</style>
