<template lang='pug'>
main-layout#page-accept-invite(no-nav)
  template(v-if='!verificationPayload.inviteToken || getInviteRequest.isError')
    template(v-if='!verificationPayload.inviteToken')
      h2 Invalid invite link
    template(v-else)
      h2 Error processing invite link.
      error-message(:request-status='getInviteRequest')
    .page-change
      p Please use the password reset page to recieve another verification email.
      v-button(:to="{ name: 'forgot-password' }") Get New Token
    
  template(v-else-if='getInviteRequest.isPending')
    h2 Loading...
  template(v-else)
    h2 Welcome {{ inviteUser.firstName }}!
    p
      | This invite is for <b>{{ inviteUser.firstName }} {{ inviteUser.lastName }}</b>
      |  - <b>{{ inviteUser.email }}</b>
      | .
      br
      | Not you? Please <a href='mailto:support@agency360sf.com' target='_blank'>contact support</a>.

    form-group(label='Use your Google account')
      form-row
        div(slot='instructions')
          | If you use gmail/google apps, we recommend you use Google login.
          br
          | Please note, <b>your google email must match this invite!</b>
        form-input(type='container' :instructions='`must be ${inviteUser.email}`')
          v-button(
            icon='google-color-g' size='large'
            @click='createWithGoogleButtonHandler'
            :loading='!passwordMode && acceptInviteRequest.isPending' loading-text='Creating your account'
            theme='white-with-border'
          ) Sign in with Google
        error-message(v-if='!passwordMode' :request-status='acceptInviteRequest')

    p.align-center - or -

    form-group(label='Create a password')
      form-row
        div(slot='instructions') Alternatively, you can create a password to use with your account.
        form-input(
          type='password' v-model='verificationPayload.newPassword'
          label='Select a password' allow-show-password
          required check-password-strength
        )
        form-input(type='container')
          v-button(
            @click='createWithPasswordButtonHandler'
            :loading='passwordMode && acceptInviteRequest.isPending' loading-text='Creating your account'
          ) Create your account
        error-message(v-if='passwordMode' :request-status='acceptInviteRequest')

  oauth-connect(
    ref='oauthConnect'
    :provider-config='oauthProviderConfig'
    @error='oauthFailure' @code='oauthSuccess'
  )
</template>

<script>
import { mapGetters } from 'vuex';

import { vuelidateGroupMixin } from '@/components/forms/vuelidate-group';
import { mapRequestStatuses } from '@/utils/vuex-api-utils';

const components = {
  'main-layout': require('@/app-public/common/layout/main-layout').default,
  'oauth-connect': require('@/components/other/oauth-connect').default,
};

export default {
  components,
  mixins: [vuelidateGroupMixin],
  metaInfo: {
    title: 'Create Your Account',
  },
  data() {
    return {
      verificationPayload: {
        inviteToken: this.$route.query.code,
      },
    };
  },
  computed: {
    ...mapGetters('auth', ['inviteUser', 'oauthProviderConfig']),
    ...mapRequestStatuses({
      getInviteRequest: 'auth/GET_INVITE_USER',
      acceptInviteRequest: 'auth/ACCEPT_INVITE',
    }),
    passwordMode() { return !!this.verificationPayload.newPassword; },
  },
  beforeMount() {
    const inviteToken = this.$route.query.code;
    if (inviteToken) {
      this.$store.dispatchApiAction('auth/GET_INVITE_USER', { inviteToken });
    }
  },
  methods: {
    createWithGoogleButtonHandler() {
      this.verificationPayload.newPassword = null; // used to toggle where to show loading/errors
      this.$refs.oauthConnect.launch('google');
    },
    async createWithPasswordButtonHandler() {
      if (this.$hasError()) return;
      await this.$store.dispatchApiAction('auth/ACCEPT_INVITE', this.verificationPayload);
      if (this.acceptInviteRequest.isSuccess) {
        this.$router.push({ name: 'welcome' });
      }
    },
    async oauthSuccess(payload) {
      await this.$store.dispatchApiAction('auth/ACCEPT_INVITE', {
        ...this.verificationPayload,
        ...payload,
      });
      if (this.acceptInviteRequest.isSuccess) {
        this.$router.push({ name: 'welcome' });
      }
    },
    oauthFailure() {

    },
  },
};
</script>

<style lang='less'>

</style>
