/* eslint-disable object-property-newline */

import _ from 'lodash';
import Vue from 'vue';
import Router from 'vue-router';
import promiseDelay from 'promise-delay';
import storage from 'local-storage-fallback'; // polyfill storage - falls back to cookies, memory, etc

import analytics from '@/utils/analytics';

import store from './vuex-store';

Vue.use(Router);

const router = new Router({
  mode: 'history',
  scrollBehavior(to, from, savedPosition) {
    return savedPosition || { x: 0, y: 0 };
  },
  routes: [
    // public pages
    { path: '/', name: 'home', component: require('./pages/home').default },
    { path: '/login', name: 'login', component: require('./pages/login').default },
    { path: '/forgot-password', name: 'forgot-password', component: require('./pages/forgot-password').default },
    { path: '/reset-password', name: 'reset-password', component: require('./pages/reset-password').default },
    { path: '/verify', name: 'verify', component: require('./pages/verify-email').default },

    { path: '/r/:resumeShareKey', props: true, name: 'view-shared-resume', component: require('./pages/view-shared-resume').default },

    // signup page
    { path: '/signup', name: 'resume-signup', component: require('./pages/resume-signup').default },

    { path: '/terms-of-service', name: 'terms-of-service', component: require('./pages/terms-of-service').default },
    { path: '/privacy-policy', name: 'privacy-policy', component: require('./pages/privacy-policy').default },
    { path: '/faq', name: 'faq', component: require('./pages/faq').default },

    // pages for logged in users
    { path: '/resumes', name: 'resume-list', component: require('./pages/resume-list').default, meta: { requiresAuth: true } },
    {
      path: '/resume/:selectedResumeId',
      component: require('./pages/resumes').default,
      props: true,
      children: [
        { path: '', name: 'resume', redirect: { name: 'resume-edit' } },
        {
          path: 'edit', name: 'resume-edit',
          component: require('./pages/resumes/edit-subpage').default,
          props: true,
        },
        {
          path: 'preview', name: 'resume-preview',
          component: require('./pages/resumes/preview-subpage').default,
          props: true,
        },
        {
          path: 'share', name: 'resume-share',
          component: require('./pages/resumes/share-subpage').default,
          props: true,
        },
      ],
    },

    {
      path: '/welcome',
      component: require('./pages/welcome').default,
      name: 'welcome',
      meta: { requiresAuth: true },
      children: [
        { path: 'profile', name: 'profile', component: require('./pages/welcome/profile-subpage').default },
      ],
    },

    { path: '/account', name: 'account', component: require('./pages/account').default, meta: { requiresAuth: true } },

    { path: '/oauth-callback', name: 'oauth-callback', component: require('./pages/oauth-callback').default },
    { path: '/logout', name: 'logout',
      beforeEnter(t, f, next) {
        analytics.track('fe_logout');
        store.dispatch('auth/browserLogout');
        return next('/');
      },
    },

    { path: '*', name: 'not-found', component: require('./pages/404').default },
  ],
});

router.beforeEach(async (to, from, next) => {
  // check all matched route records (includes parent) if we need auth
  if (to.matched.some((record) => record.meta.requiresAuth)) {
    if (!store.getters['auth/userIsLoggedIn']) {
      return next({
        name: 'login',
        query: { redirect: to.fullPath },
      });
    }

    // wait until initial get user request finishes to enter the route
    // this makes the logic in each page easier as we can assume the user is loaded

    let getUserRequest;
    let getEnumsRequest;
    do {
      /* eslint-disable no-await-in-loop */
      // TODO: handle errors, do not allow infinite loop!
      // note - we have to call the get fn on each loop to get the latest value
      getUserRequest = store.getters.requestStatus('authUser/profile/GET_USER');
      getEnumsRequest = store.getters.requestStatus('enums/GET_ENUMS');
      if (getEnumsRequest.isEmpty) store.dispatchApiAction('enums/GET_ENUMS');
      await promiseDelay(50);
    } while (getUserRequest.isPending || getEnumsRequest.isPending);
  }


  // automatically cast any integer params into proper integers
  // so that components can set params to expect a Number
  // NOTE - this is so that when we user router link or programatically navigate
  // that we can set the param as an int
  if (to.params) {
    const castNumericParams = _.mapValues(to.params, (val) => {
      if (parseInt(val).toString() === val) return parseInt(val);
      return val;
    });
    if (_.isEqual(castNumericParams, to.params)) return next();
    return next({
      ...to,
      params: castNumericParams,
    });
  }

  return next();
});

router.afterEach((to, from) => { // eslint-disable-line no-unused-vars
  const pageProps = {};
  if (to.redirectedFrom) pageProps.redirected_from = to.redirectedFrom;

  // we must do this on nextTick so we get the actual URL
  setTimeout(() => analytics.page(to.name, pageProps));

  if (to.meta.disableIntercom || storage.getItem('leq-impersonate-auth-token')) {
    window.intercomSettings = { hide_default_launcher: true };
  } else {
    window.intercomSettings = { hide_default_launcher: false };
  }
});

export default router;
