import _ from 'lodash';
import Vue from 'vue';
import storage from 'local-storage-fallback'; // polyfill storage - falls back to cookies, memory, etc
import jwtDecode from 'jwt-decode';

import { buildApiActions } from '@/utils/vuex-api-utils';
import analytics from '@/utils/analytics';

const AUTH_TOKEN = 'leq-auth-token';
const ADMIN_AUTH_TOKEN = 'leq-admin-auth-token';
const IMPERSONATE_AUTH_TOKEN = 'leq-impersonate-auth-token';

// used by login, oauth login, accept invite
function loginMutation(state, { response }) {
  state.token = response.authToken;
  state.userId = response.user.id;
  state.adminImpersonatingUser = false;
  storage.setItem(AUTH_TOKEN, response.authToken);
  analytics.identify(response.user.id);
}

export default {
  namespaced: true,
  state() {
    return {
      token: null,
      userId: null,
      adminToken: null,
      adminId: null,
      adminImpersonatingUser: false,
      inviteUser: null,
    };
  },
  getters: {
    authUserId: (state) => state.userId,
    authToken: (state) => state.token,
    userIsLoggedIn: (state) => !!state.token,
    adminImpersonatingUser: (state) => state.adminImpersonatingUser,

    currentAdminId: (state) => state.adminId,
    adminAuthToken: (state) => state.adminToken,
    userIsLoggedInAsAdmin: (state) => !!state.adminToken,

    inviteUser: (state) => state.inviteUser,

    // just an easy way to make this config available wherever it needs to be
    oauthProviderConfig: () => ({
      google: {
        authorizeUri: 'https://accounts.google.com/o/oauth2/v2/auth',
        clientId: process.env.GOOGLE_OAUTH_CLIENT_ID,
        redirectUri: '/oauth-callback',
        scopes: [
          'email',
          'profile',
          'openid',
        ],
        scopesDelimiter: ' ', // default is ","
        accessType: 'offline',
        prompt: 'consent',
      },
    }),

  },
  ...buildApiActions({
    LOGIN: {
      action: (ctx, payload) => ({
        method: 'post',
        url: '/auth/login',
        params: payload, // email, password
        afterSuccess: async (response) => {
          await ctx.dispatch('authUser/profile/setUserData', response.user, { root: true });
        },
      }),
      mutation: loginMutation,
    },

    OAUTH_LOGIN: {
      action: (ctx, payload) => ({
        method: 'post',
        url: '/auth/oauth-login',
        params: payload, // provider, code, etc
        afterSuccess: async (response) => {
          await ctx.dispatch('authUser/profile/setUserData', response.user, { root: true });
        },
      }),
      mutation: loginMutation,
    },


    ADMIN_LOGIN: {
      action: (ctx, payload) => ({
        method: 'post',
        url: '/auth/admin-login',
        params: payload, // email, password
      }),
      mutation: (state, { response }) => {
        state.adminToken = response.authToken;
        state.adminId = response.admin.id;
        storage.setItem(ADMIN_AUTH_TOKEN, response.authToken);
      },
    },

    GET_INVITE_USER: {
      action: (ctx, payload) => ({
        method: 'get',
        url: `/invites/${payload.inviteToken}`,
      }),
      mutation: (state, { response }) => {
        state.inviteUser = response;
      },
    },
    ACCEPT_INVITE: {
      action: (ctx, payload) => ({
        method: 'post',
        url: `/invites/${payload.inviteToken}/accept`,
        params: _.omit(payload, 'inviteToken'),
        afterSuccess: async (response) => {
          await ctx.dispatch('authUser/profile/setUserData', response.user, { root: true });
        },
      }),
      mutation: loginMutation,
    },

    LOGOUT: {
      action: (ctx, payload) => ({
        method: 'post',
        url: '/auth/logout',
      }),
      mutation: (state, { response }) => {

      },
    },

    REQUEST_ACCOUNT_VERIFY: {
      action: (ctx, payload) => ({
        method: 'post',
        url: '/auth/request-account-verify',
        params: payload, // email
      }),
    },

    REQUEST_PASSWORD_RESET: {
      action: (ctx, payload) => ({
        method: 'post',
        url: '/auth/request-password-reset',
        params: payload, // email
      }),
    },
    SUBMIT_PASSWORD_RESET: {
      action: (ctx, payload) => ({
        method: 'post',
        url: '/auth/password-reset',
        params: payload, // email, resetToken, newPassword
      }),
    },

    RESUME_SIGNUP: {
      action: (ctx, payload) => ({
        method: 'post',
        url: '/resume-signup',
        params: payload,
        afterSuccess: async (response) => {
          await ctx.dispatch('authUser/profile/setUserData', response.user, { root: true });
        },
      }),
      mutation: loginMutation,
    },

    AUTH_AS_USER: {
      action: (ctx, payload) => ({
        method: 'post',
        url: '/auth/force-login',
        params: payload, // userId
        afterSuccess(response) {
          storage.setItem(IMPERSONATE_AUTH_TOKEN, response.authToken);
          window.open('/resumes', '_blank');
        },
      }),
    },
  }, {
    actions: {
      async initAdminAuthFromStorage(ctx) {
        const adminToken = storage.getItem(ADMIN_AUTH_TOKEN);
        if (adminToken) {
          const { adminId } = jwtDecode(adminToken);
          await ctx.commit('SET_ADMIN_AUTH_FROM_STORAGE', {
            adminToken,
            adminId,
          });
        }
      },

      async initAuthFromStorage(ctx) {
        let adminImpersonatingUser = false;
        let token = storage.getItem(AUTH_TOKEN);

        const impersonateToken = storage.getItem(IMPERSONATE_AUTH_TOKEN);
        if (impersonateToken) {
          token = impersonateToken;
          adminImpersonatingUser = true;
        }

        if (token) {
          const { userId } = jwtDecode(token);
          analytics.identify(userId);

          await ctx.commit('SET_AUTH_FROM_STORAGE', {
            token,
            userId,
            adminImpersonatingUser,
          });
          await ctx.dispatch('authUser/profile/setUserId', userId, { root: true });
        }
      },
      async browserLogout(ctx) {
        storage.removeItem(AUTH_TOKEN);
        storage.removeItem(IMPERSONATE_AUTH_TOKEN);
        ctx.commit('CLEAR_AUTH');
      },
      async browserAdminLogout(ctx) {
        storage.removeItem(ADMIN_AUTH_TOKEN);
        ctx.commit('CLEAR_ADMIN_AUTH');
      },
    },
    mutations: {
      CLEAR_AUTH: (state) => {
        state.token = null;
        state.userId = null;
        state.adminImpersonatingUser = false;
      },
      SET_AUTH_FROM_STORAGE: (state, { token, userId, adminImpersonatingUser }) => {
        state.token = token;
        state.userId = userId;
        state.adminImpersonatingUser = adminImpersonatingUser;
      },
      CLEAR_ADMIN_AUTH: (state) => {
        state.adminToken = null;
        state.adminId = null;
      },
      SET_ADMIN_AUTH_FROM_STORAGE: (state, { adminToken, adminId }) => {
        state.adminToken = adminToken;
        state.adminId = adminId;
      },
    },
  }),
};
