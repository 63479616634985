<template lang='pug'>
.landing-page-section(
  :class='computedClasses'
  :style='computedStyles'
)
  .landing-page-section__extras
  .landing-page-section__wrap

    img.landing-page-section__image(
      v-if='imageUrl'
      :src='imageUrl'
    )

    .landing-page-section__content
      slot

      v-button(
        v-if='ctaText'
        size='large'
        :theme='buttonTheme'
        :to-named-route='ctaLinkToNamedRoute'
      ) {{ ctaText }}

</template>


<script>
const components = {
};

export default {
  components,
  props: {
    title: String,
    subtitle: String,
    theme: { type: String, default: 'white' },
    ctaText: String,
    ctaLinkToNamedRoute: { type: String, default: 'company-signup' },
    bgImageUrl: String,
    imageUrl: String,
    imageRight: Boolean,
    textLeft: Boolean,
    textRight: Boolean,
    rounded: Boolean,
  },
  computed: {
    buttonTheme() {
      if (this.theme === 'pink') return 'dark';
      if (this.theme === 'white') return 'dark';
      return 'pink';
    },
    computedClasses() {
      return {
        [`landing-page-section--theme-${this.theme}`]: true,
        'landing-page-section--image-right': this.imageRight,
        'landing-page-section--text-left': this.textLeft,
        'landing-page-section--text-right': this.textRight,
        'landing-page-section--with-bg-image': this.bgImageUrl,
        // 'landing-page-section--rounded': this.rounded, // Disabled rounded option/styling for now.
      };
    },
    computedStyles() {
      return {
        ...this.bgImageUrl && { backgroundImage: `url(${this.bgImageUrl})` },
      };
    },
  },
};
</script>

<style lang='less'>

.landing-page-section {
  position: relative;




  img {
    max-width: 100%;
  }
  .landing-page-section__wrap {
    max-width: @max-width;
    margin: 0 auto;
    display: flex;
    padding: 60px 50px;
    justify-content: space-around;
    @media @mq-small-only {
      display: block;
      padding: 20px 10px;
    }
  }
  &.landing-page-section--image-right {
    .landing-page-section__wrap {
      flex-direction: row-reverse;
    }
  }

  .landing-page-section__image {
    // min-width: 35%;
    max-width: 50%;
    max-height: 350px;

    @media @mq-small-only {
      max-width: unset;
      max-width: 80%;
      display: block;
      margin: 30px auto;
    }
  }



  .landing-page-section__content {
    // max-width: @max-width;
    width: 100%;
    margin: 0 auto;
    padding: 0px 20px;
    text-align: center;
    margin-left: 10px;
    margin-right: 10px;
    align-items: center;
    position: relative;
    z-index: 2;

    @media @mq-small-only {
      font-size: .9rem;
      margin: 0;
    }


    h1 {
      @media @mq-small-only {
        font-size: 1.3rem;
      }
    }


    p {
      @media @mq-small-only {
        font-size: .8rem;
      }
    }



    h2 {
      @media @mq-small-only {
        font-size: 1rem;
      }
      // color: rgb(26, 55, 82);
      font-weight: bold;
      // text-align: left;
      line-height: 1.5em;
      // justify-self: start;
      // align-self: start;
    }

    h3 {
      @media @mq-small-only {
        font-size: .9rem;
      }
      color: rgba(255,255,255, .7);
      font-size: 1.3em;
      line-height: 1.5em;
      font-weight: normal;
    }

    .landing-page-section--text-left {
      // h2, h3 {
      //   text-align:left;
      // }
    }

    .landing-page-section--text-right {
      // h2, h3 {
      //   text-align:right;
      // }
    }
  }

  em {
    font-style: inherit;
  }

  &.landing-page-section--theme-navy {
    background: @navy;
    color: white;
    em { color: @brand-pink; }
  }
  &.landing-page-section--theme-pink {
    background: @brand-pink;
    color: white;
    em { color: @navy; }
  }
  &.landing-page-section--theme-white {
    background: #FFF;
    color: @navy;
    h3 { color: fade(@navy, 70); }
    em { color: @brand-pink; }
  }
  &.landing-page-section--theme-off-white {
    background: @off-white;
    color: @navy;
    h3 { color: fade(@navy, 70); }
    em { color: @brand-pink; }
  }
  &.landing-page-section--theme-gray {
    background:@brand-gray;
    color:@navy;
    h3 { color: fade(@navy, 70); }
    em { color: @brand-pink; }
  }

  &.landing-page-section--with-bg-image {
    background-size: cover;
    background-position: center center;
    padding-top: 100px;
    padding-bottom: 100px;

    .landing-page-section__content {
      background: rgba(0,0,0,.4);
    }
  }

  &.landing-page-section--rounded {

    &:before, &:after {
      content: '';
      width: 120%;
      left: -20%;
      margin-right: 0px;
      height: 30px;
      background: inherit;
      // background: red;
      position: absolute;
      top: -15px;
      border-radius: 100% 0% 0 0;
      z-index: 1;

      @media @mq-small-only {
        height: 15px;
        top: -7px;
      }

    }
    &:after {
      top: unset;
      bottom: -15px;
      border-radius: 0 0 0% 100%;
      @media @mq-small-only {
        bottom: -7px;
      }
    }


  }





  // padding-bottom: 35px;

  // border-top-right-radius: 80px;
  // // border-bottom-left-radius: 50px;
  // &:before {
  //   content: '';
  //   position: absolute;
  //   right: 0;
  //   top: 100%;
  //   width: 80px;
  //   height: 80px;
  //   background: inherit;
  // }



  .landing-page-section {
    margin-top: 30px;
    padding: 30px;
  }
}
</style>
