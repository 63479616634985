<template lang='pug'>
.resume-list
  template(v-if='hasResumes')
    .resume(v-for='resume in resumes' :key='`resume-${ resume.id }`' @click='onResumeClicked(resume)')
      .resume__label
        .resume__icon
          icon(:name='iconForResume(resume)' scale="1")
        .resume__name {{ resume.name || 'Resume' }}
      .resume__actions(v-if='showResumeActions(resume)')
        v-button(size='small' icon='trash' theme='dark' @click='onDeleteResumeButtonHandler(resume)') Delete
  template(v-else)
    .no-resumes {{ noResumesMessage }}
  template(v-if='showCreateResume')
    v-button(
      @click='onCreateResumeButtonHandler'
    ) Create New Resume
  popup(ref='deleteResumePopup' title='Delete Resume?')
      form-row(v-if='resumeToDelete' no-inputs)
        p The resume "{{ resumeToDelete.name }}" will be deleted immediately.
      form-row(slot='bottom')
        error-message(:request-status='deleteResumeRequest')
        v-button(
          @click='confirmDeleteResumeHandler'
          :request-status='deleteResumeRequest' loading-text='Deleting...'
        ) Confirm Delete
  popup(ref='createResumePopup' title='Create Resume')
      form-row
        form-input(
          v-model='newResume.name'
          label='Resume Name'
          required
        )
      form-row(slot='bottom')
        error-message(:request-status='createResumeRequest')
        v-button(
          @click='confirmCreateResumeHandler'
          :request-status='createResumeRequest' loading-text='Creating...'
        ) Create Resume
</template>

<script>
import _ from 'lodash';

const components = {};

export default {
  components,
  data() {
    return {
      newResume: { name: 'My Resume' },
      resumeToDelete: null,
    };
  },
  props: {
    resumes: { type: Array },
    noResumesMessage: { type: String, default: '' },
    /**
     * Used to decide whether or not the user can perform changes.
     */
    currentUserId: {},
    hideCreateResume: { type: Boolean },
    handleDeleteResume: { type: Function },
    deleteResumeRequest: {},
    handleCreateResume: { type: Function },
    createResumeRequest: {},
  },
  computed: {
    hasResumes() {
      return this.resumes && this.resumes.length > 0;
    },
    computedStyles() {
      return {};
    },
    showCreateResume() {
      return !this.hideCreateResume;
    },
  },
  methods: {
    iconForResume(resume) {
      return 'building'; // TODO: Change
    },
    showResumeActions(resume) {
      const resumeBelongsToCurrentUser = resume.userId === this.currentUserId;
      return resumeBelongsToCurrentUser;
    },
    onResumeClicked(resume) {
      console.log('Resume Clicked', resume);
      this.$emit('resumeClick', { resume });
    },
    onDeleteResumeButtonHandler(resume) {
      this.resumeToDelete = resume;
      this.$refs.deleteResumePopup.open();
    },
    async confirmDeleteResumeHandler() {
      await this.handleDeleteResume(this.resumeToDelete);
      if (this.deleteResumeRequest.isSuccess) {
        this.resumeToDelete = null;
        this.$refs.deleteResumePopup.close();
      }
    },
    onCreateResumeButtonHandler() {
      this.$refs.createResumePopup.open();
    },
    async confirmCreateResumeHandler() {
      await this.handleCreateResume(this.newResume);

      if (this.createResumeRequest.isSuccess) {
        this.newResume = {};

        if (this.$refs.createResumePopup) {
          this.$refs.createResumePopup.close();
        }
      }
    },
  },
};
</script>

<style lang='less'>
.resume-list {
  column-count: 2;
  padding: 20px 0;
  display: flex;
  flex-direction: column;

  .resume {
    display: flex;
    margin-bottom: 4px;

    padding: 12px;
    border-radius: 5px;
    background: #336b9f;
    color: white;
    cursor: pointer;

    .resume__label {
      flex: 1 0 0;
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: flex-start;
      align-items: center;
      display: flex;

      .resume__name {
        font-weight: bold;
        margin-left: 6px;
        padding: 0;
      }

      .resume__size,
      .resume__date {
        font-size: 0.9em;
        margin-left: 6px;
        padding: 0;
        font-weight: 400;
        opacity: 0.8;
      }
    }

    .resume__actions {
      flex: 0 0 0;
    }
  }
}
</style>