/*
  This component is just a wrapper to add some extra functionality to the existing
  table-component component that is part of the `vue-table-component` package

  NOTE -- it is registered globally along with its counterparts
*/

<template lang='pug'>
table-column(
  :label='label'
  ref='originalTableColumn'
  :data-type='dataType'
  :sort-by='sortBy'
)
  template(slot-scope='row')
    span(v-if='cellValue(row) === null || cellValue(row) === undefined && !!hasEmptyText') {{ computeEmptyText(row) }}
    template(v-else-if='type === "boolean"')
      template(v-if='!!cellValue(row)')
        //- TODO: add tooltip with _get(row, show)
        icon(name='check-circle' scale='1.5')
      template(v-else-if='cellValue(row) === false')
        icon(name='times-circle' scale='1.5')
      span(v-else) -
    .small.nowrap(v-else-if='type === "datetime"') {{ cellValue(row) | datetime }}
    .small.nowrap(v-else-if='type === "date"') {{ cellValue(row) | date }}
    span(v-else-if='type === "timeago"') {{ cellValue(row) | timeago }}
    span(v-else-if='type === "percent"') {{ cellValue(row) | percent }}
    span(v-else-if='type === "money"') {{ cellValue(row) | currency }}
    span(v-else-if='type === "filesize"') {{ cellValue(row) | filesize }}
    span(v-else-if='type === "delta"') {{ cellValue(row) > 0 ? '+'+cellValue(row) : cellValue(row) }}
    span(v-else-if='type === "speakOption"') {{ cellValue(row) | speakOption }}
    span(v-else-if='type === "option"') {{ cellValue(row) | valueForKey(options) | defaultValue(computeEmptyText(row)) }}
    span(v-else) {{ cellValue(row) }}
</template>

<script>
import _ from 'lodash';

const components = {
  icon: require('./icon').default,
};

export default {
  components,
  props: {
    type: { type: String, default: 'text' },
    show: String,
    label: String,
    emptyText: String,
    emptyTextShow: String,
    valueFn: Function,
    options: { type: Object },
  },
  computed: {
    dataType() {
      // for sorting - what type of data is this
      return (
        {
          date: 'string', // as long as our values are ISO format (YYYY-MM-DD)
          datetime: 'string', // then we can sort as a string and skip using moment.js
          timeago: 'string',
          percent: 'numeric',
          money: 'numeric',
          filesize: 'numeric',
          delta: 'numeric',
          speakOption: 'string',
          industry: 'string',
          options: 'string',
        }[this.type] || 'string'
      );
    },
    sortBy() {
      // this property of row is used for sorting
      return this.show;
    },
    hasEmptyText() {
      const { emptyText, emptyTextRow } = this;
      return emptyTextRow || emptyText;
    },
  },
  methods: {
    _get: _.get,
    formatter(v) {
      return v;
    },
    cellValue(row, show = this.show) {
      if (show) {
        return _.get(row, show);
      } else if (this.valueFn) {
        return this.valueFn(row);
      }

      return null;
    },
    computeEmptyText(row) {
      const { emptyText, emptyTextRow } = this;
      return (
        (emptyTextRow ? this.cellValue(row, emptyTextRow) : '') || emptyText
      );
    },
    // sortable: { default: true, type: Boolean },
    // sortBy: { default: null },
    // filterable: { default: true, type: Boolean },
    // filterOn: { default: null },
    // hidden: { default: false, type: Boolean },
  },
};
</script>

<style lang='less'>
</style>
