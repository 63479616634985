<template lang='pug'>
.profile-photo(
  :style='computedStyles' @click='$emit("click")'
  :class='`size--${sizeCategory}`'
)
  .profile-photo-frame
    img(v-if='user.profilePic' :src='user.profilePic')
    icon(v-else name='user-tie')
    .hover-overlay(v-if='editable')
      icon(name='edit')
  .profile-photo-placard(v-if='placardText')
    .profile-photo-placard-inner {{ placardText }}

</template>

<script>
import _ from 'lodash';

const components = {
};

const VALID_SIZE_STRINGS = {
  small: 60,
  medium: 100,
  large: 200,
};

export default {
  components,
  props: {
    user: { type: Object },
    placardText: String,
    size: { default: 'small' },
    editable: Boolean,
  },
  computed: {
    sizeInPixels() {
      if (_.isInteger(this.size)) return this.size;
      if (VALID_SIZE_STRINGS[this.size]) return VALID_SIZE_STRINGS[this.size];
      return VALID_SIZE_STRINGS.small;
    },
    sizeCategory() {
      if (this.sizeInPixels < 100) return 'small';
      if (this.sizeInPixels < 200) return 'medium';
      if (this.sizeInPixels < 400) return 'large';
      return 'xlarge';
    },
    computedStyles() {
      return {
        width: `${this.sizeInPixels}px`,
        height: `${this.sizeInPixels}px`,
      };
    },
  },
};
</script>

<style lang='less'>
.profile-photo {
  position: relative;

  .profile-photo-frame {
    position: relative;
    border-radius: 50%;
    overflow: hidden;
    border: 2px solid @brand-pink;
    background: #BBB;
    width: inherit;
    height: inherit;
    text-align: left;

    > img {
      width: 100%;
      position: absolute;
    }
    > .icon {
      position: absolute;
      width: 100%;
      height: 100%;
      padding-top: 15%;
      fill: rgba(0,0,0,.15);
      display: flex;
      align-items: center;
      justify-items: center;

    }
  }

  .profile-photo-placard {
    position: absolute;
    bottom: 0px;
    width: 100%;
    text-align: center;
    padding: 0;
    display: flex;
    justify-content: center;

    .profile-photo-placard-inner {
      margin: 0;
      max-width: 90%;
      background: @brand-pink;
      color: white;
      border-radius: 3px;
      text-transform: uppercase;
    }
  }
  &.size--small {
    .profile-photo-placard-inner {
      font-size: 10px;
      line-height: 12px;
      padding: 1px .8em 0px;
    }
  }
  &.size--medium {
    .profile-photo-placard-inner {
      font-size: 11px;
      line-height: 14px;
      padding: .15em .5em .1em;
      font-weight: bold;
    }
  }
  &.size--large {
    .profile-photo-placard-inner {
      font-size: 14px;
      line-height: 16px;
      padding: .15em 1.5em .1em;
      font-weight: bold;
    }
  }

  .slot-content {
    position: relative;
  }
  .hover-overlay {
    background: fade(@navy, 50);
    width: 100%;
    height: 100%;
    position: relative;
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    opacity: 0;
    .icon {
      width: 30px;
      height: 30px
    }
  }

  &:hover {
    .hover-overlay {
      opacity: 1;
    }
  }

}
</style>