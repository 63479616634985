<template lang='pug'>
main-layout#page-user-resumes(
  full-width
  no-footer
)
  .resumes-content
    template(v-if='getResumesRequest.isPending')
      h3 Loading resumes list...
    template(v-else-if='getResumesRequest.isError')
      error-message(:request-status='getResumesRequest')
    template(v-else-if='getResumesRequest.isSuccess')
      .page-header
        h2 Resumes
        resume-list(:current-user-id='userId' @resumeClick='onClickResume'
        :handle-delete-resume='handleDeleteResume' :delete-resume-request='deleteResumeRequest'
        :handle-create-resume='handleCreateResume' :create-resume-request='createResumeRequest'
        :resumes='resumes')

</template>

<script>
import _ from 'lodash';
import { mapRequestStatuses } from '@/utils/vuex-api-utils';
import { mapGetters } from 'vuex';

const components = {
  'main-layout': require('@/app-public/common/layout/main-layout').default,
  'resume-list': require('@/components/other/resume-list').default,
};

export default {
  components,
  mixins: [],
  metaInfo: {
    title: 'Resumes',
  },
  data() {
    return {};
  },
  computed: {
    ...mapGetters('auth', ['userIsLoggedIn']),
    ...mapGetters('authUser/profile', ['user', 'userId']),
    ...mapGetters('authUser/resumes', [
      'resumes',
      'hasResumesLoaded',
      'newResumeId',
    ]),
    ...mapRequestStatuses({
      getEnumsRequest: 'enums/GET_ENUMS',
      getResumesRequest: 'authUser/resumes/LOAD_RESUMES',
      createResumeRequest: 'authUser/resumes/CREATE_RESUME',
      deleteResumeRequest: 'authUser/resumes/DELETE_RESUME',
    }),
  },
  watch: {},
  methods: {
    onClickResume({ resume }) {
      console.log('Resume clicked.', resume);
      this.$router.push({ name: 'resume-edit', params: { selectedResumeId: resume.id } });
    },
    async handleCreateResume(newResume) {
      await this.$store.dispatch('authUser/resumes/createResume', newResume);
      const { newResumeId } = this;
      this.$router.push({ name: 'resume-edit', params: { selectedResumeId: newResumeId } });
    },
    async handleDeleteResume(resume) {
      return this.$store.dispatch('authUser/resumes/deleteResume', resume.id);
    },
  },
  beforeMount() {
    if (!this.userIsLoggedIn) {
      // redirect if not logged in
      this.$router.replace({ name: 'resume-signup' });
    } else if (!this.getResumesRequest.success) {
      this.$store.dispatch('authUser/resumes/loadResumes');
    }
  },
};
</script>

<style lang='less'>
#page-user-resumes {
  .resumes-content {
    max-width: 1000px;
    padding: 20px;
    margin: 0 auto;
  }
}
</style>
