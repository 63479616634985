import _ from 'lodash';
import Vue from 'vue';
// import * as Sentry from '@sentry/browser';

import { buildApiActions } from '../utils/vuex-api-utils';

export default {
  namespaced: true,
  state() {
    return {
      contactTimes: {},
      companySizes: {},
      languages: {},
      skills: {},
      countries: {},
      industryOptions: {},
      presentationTypeOptions: {},
      roleOptions: {
        similar: 'Similar Role',
        promotion: 'Promotion',
        changeIndustry: 'Change Industry',
        changeProfession: 'Change Profession',
      },
      careerSpeakOptions: {
        yesEmail: 'Yes, please email me.',
        yesPhone: 'Yes, please contact me by phone.',
        notNow: 'No, not now.',
      },
      companyStatus: {
        new: 'New',
        demo: 'Demo',
        active: 'Active',
        paused: 'Paused',
        canceled: 'Cancelled',
      },
      companyAssessmentOptions: {
        leadereq: 'LeaderEQ',
        disabled: 'Disabled',
      },
      companySkillsOptions: {
        leadereq: 'LeaderEQ',
        custom: 'Custom',
      },

      jobStatusOptions: {},
      jobPositionTypeOptions: {},
      jobCategoryOptions: {},

      applicationStage: {},
    };
  },
  getters: {
    contactTimeOptions: (state) => state.contactTimes,
    companySizeOptions: (state) => state.companySizes,
    industryOptions: (state) => state.industryOptions,
    roleOptions: (state) => state.roleOptions,
    languageOptions: (state) => state.languages,
    skillOptions: (state) => state.skills,
    countryOptions: (state) => state.countries,
    presentationTypeOptions: (state) => state.presentationTypeOptions,
    careerSpeakOptions: (state) => state.careerSpeakOptions,

    jobStatusOptions: (state) => state.jobStatusOptions,
    jobPositionTypeOptions: (state) => state.jobPositionTypeOptions,
    jobCategoryOptions: (state) => state.jobCategoryOptions,

    applicationStage: (state) => state.applicationStage,
  },
  ...buildApiActions({
    // single utility route to fetch a bunch of dropdown options
    GET_ENUMS: {
      action: (ctx, payload) => ({
        method: 'get',
        url: '/enums',
      }),
      mutation: (state, { response }) => {
        // has all the same keys as state
        _.assign(state, response);
      },
    },
  }),
};
