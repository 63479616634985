<template lang='pug'>
main-layout#page-company-resume-detail(
  no-footer
)
  template(v-if='getResumesRequest.isPending')
  template(v-else-if='getResumesRequest.isError')
    error-message(:request-status='getResumesRequest')
  template(v-else-if='getEnumsRequest.isError')
    error-message(:request-status='getEnumsRequest')
  template(v-else-if='getResumesRequest.isSuccess && getEnumsRequest.isSuccess')
    template(v-if='resume !== undefined')
      //- Header and Navigation
      .resume-page-header
        .label
          span.resume-name
            | {{ resume.name }}
        .header-links
          nav.account-links
            router-link(:to='{name: "resume-edit"}') Edit
            router-link(:to='{name: "resume-preview"}') Preview
            router-link(:to='{name: "resume-share"}') Share
      //- Content View
      router-view
    template(v-if='!resume')
      p Resume Not Found

</template>

<script>
import _ from 'lodash';
import { mapGetters } from 'vuex';

import { vuelidateGroupMixin } from '@/components/forms/vuelidate-group';
import { mapRequestStatuses } from '@/utils/vuex-api-utils';

import { BEST_CONTACT_TIMES } from '@/data/best-contact-times';
import { TIMEZONES } from '@/data/timezones';

const components = {
  'main-layout': require('@/app-public/common/layout/main-layout').default,
};

export default {
  components,
  mixins: [vuelidateGroupMixin],
  props: {
    selectedResumeId: Number,
  },
  data() {
    return {};
  },
  computed: {
    ...mapGetters('auth', ['userIsLoggedIn']),
    ...mapGetters('authUser/resumes', ['currentResume', 'hasResumesLoaded']),
    ...mapRequestStatuses({
      getResumesRequest: 'authUser/resumes/LOAD_RESUMES',
      getEnumsRequest: 'enums/GET_ENUMS',
    }),
    resume() {
      return this.currentResume;
    },
  },
  async beforeMount() {
    await this.$store.dispatch(
      'authUser/resumes/setCurrentResumeId',
      this.selectedResumeId,
    );

    if (!this.getEnumsRequest.isSuccess) {
      this.$store.dispatchApiAction('enums/GET_ENUMS');
    }
  },
  watch: {
    selectedResumeId() {
      console.log('Set Resume: ', this.selectedResumeId);
      this.$store.dispatch(
        'selectedUser/profile/setUserId',
        this.selectedResumeId,
      );
    },
  },
  methods: {},
};
</script>

<style lang='less'>
.back-to-dashboard {
  margin-top: 12px;
  text-align: center;

  span {
    padding-left: 8px;
    font-size: 14px;
  }
}

.resume-page-header {
  padding: 20px 20px;
  margin-top: 20px;
  text-align: center;
  border-bottom: solid rgba(0, 0, 0, 0.2) 1px;
  margin-bottom: 14px;

  .label {
    font-weight: bold;
    font-size: 26px;
    margin-bottom: 20px;

    .biz-name {
      margin-left: 30px;
    }
    .resume-roles {
      float: right;
      > span {
        margin-left: 15px;
      }
    }
  }

  .id-number {
    color: #aaa;
    margin-left: 15px;
    font-weight: normal;
  }
  .contact-info {
    margin-top: 10px;
    > a {
      font-size: 14px;
      line-height: 18px;
      color: #dbfdff;
      margin-right: 25px;

      &:hover {
        text-decoration: underline;
      }
      .icon {
        margin-right: 8px;
        vertical-align: middle;
      }
    }
  }
  .biz-type {
    float: right;
    text-transform: uppercase;
  }

  .account-links {
    margin-top: 10px;

    > a {
      font-size: 16px;
      line-height: 1em;
      text-transform: uppercase;
      font-weight: bold;
      // margin-right: 20px;
      padding: 7px 20px;
      &.router-link-exact-active {
        text-decoration: underline;
      }
      &:hover {
        text-decoration: underline;
      }
      &:first-child {
        padding-left: 0;
      }
      &:last-child {
        border-right: none;
      }
    }
    .icon {
      margin-right: 8px;
      vertical-align: middle;
      // width: 20px;
      // height: 20px;
      margin-top: -4px;
    }
  }
}
</style>