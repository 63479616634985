<template lang='pug'>
.auth-verify
  template(v-if='isVerified')
    h3 Account verified
      form-row(no-inputs)
        p Your account has been verified.
  template(v-else)
    h3 Account Verification Required
    template
      form-row(no-inputs)
        p Your email address is not yet verfified.
        p In order to verify your account we will send you an email to {{ user.email }}.
      form-row()
        v-button(
          v-if='requestAccountVerifyRequest.isSuccess'
          theme='transparent-dark'
          icon='check'
        ) Check your email for instructions
        v-button(
          v-else
          theme='transparent-dark'
          @click='requestAccountVerifyButtonHandler'
          :request-status='requestAccountVerifyRequest'
          :icon='requestAccountVerifyRequest.isSuccess ? "check" : ""'
        ) Send Account Verification Email
        error-message(:request-status='requestAccountVerifyRequest')
</template>

<script>
import _ from 'lodash';
import { mapGetters } from 'vuex';

import { vuelidateGroupMixin } from '@/components/forms/vuelidate-group';
import { mapRequestStatuses } from '@/utils/vuex-api-utils';

const components = {};

export default {
  components,
  mixins: [vuelidateGroupMixin],
  data() {
    return {};
  },
  computed: {
    ...mapGetters('authUser/profile', ['user', 'isVerified']),
    ...mapRequestStatuses({
      requestAccountVerifyRequest: 'auth/REQUEST_ACCOUNT_VERIFY',
    }),
  },
  watch: {},
  methods: {
    requestAccountVerifyButtonHandler() {
      this.$store.dispatchApiAction('auth/REQUEST_ACCOUNT_VERIFY', {
        email: this.user.email,
      });
    },
  },
};
</script>

<style lang='less'>
</style>
