<template lang='pug'>
main-layout#page-dashboard(full-width)
  .onboarding-container
    router-view

</template>

<script>
import _ from 'lodash';
import { mapGetters } from 'vuex';
import promiseDelay from 'promise-delay';

import { vuelidateGroupMixin } from '@/components/forms/vuelidate-group';
import { mapRequestStatuses } from '@/utils/vuex-api-utils';

const components = {
  'main-layout': require('@/app-public/common/layout/main-layout').default,
  'onboarding-nav': require('@/app-public/common/other/onboarding-nav').default,
};

export default {
  components,
  mixins: [vuelidateGroupMixin],
  computed: {
    ...mapGetters('auth', ['userIsLoggedIn']),
    ...mapGetters('authUser/profile', [
      'user',
      'onboardingProgress',
      'userEvaluationsEnabled',
    ]),
    ...mapGetters('authUser/evaluations', ['userEvaluations']),
    ...mapRequestStatuses({
      getEvaluationsRequest: 'authUser/evaluations/GET_EVALUATIONS',
    }),
  },
  watch: {
    $route() { this.goToNextStep(); },
  },
  methods: {
    async goToNextStep() {
      // if a sub-page is not selected, go to the next incomplete step
      if (this.$router.currentRoute.name !== 'welcome') return;

      // if user is a company admin, redirect to the company users list.
      if (this.user.isCompanyAdmin) {
        this.$router.replace({ name: 'company-users-dashboard' });
        return;
      // if user is exec, wait for evals to be loaded
      } else if (this.user.isExecutive) {
        let evalsRequest;

        do {
          /* eslint-disable no-await-in-loop */
          // TODO: handle errors, do not allow infinite loop!
          // note - we have to call the get fn on each loop to get the latest value
          evalsRequest = this.$store.getters.requestStatus('authUser/evaluations/GET_EVALUATIONS');
          if (evalsRequest.isEmpty) this.$store.dispatchApiAction('authUser/evaluations/GET_EVALUATIONS');
          await promiseDelay(50);
        } while (evalsRequest.isPending);
      }

      const nextStep = _.find(this.navItems, (item) => !item.complete);
      this.$router.replace({ name: nextStep ? nextStep.routeName : 'profile' });
    },
  },
  async beforeMount() {
    if (this.user.isExecutive) {
      await this.$store.dispatchApiAction('authUser/evaluations/GET_EVALUATIONS');
    }
  },
  mounted() {
    this.goToNextStep();
  },
};
</script>

<style lang='less'>
#page-dashboard {

  .secondary-header-bar {
    height: 80px;
    padding: 8px;
    background: @navy;
    color: white;
  }

  .onboarding-container {
    max-width: 1000px;
    padding: 20px;
    margin: 0 auto;
  }

}
</style>
