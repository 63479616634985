<template lang='pug'>
.onboarding-profile-step
  h2 Edit Resume
  resume-editor(v-model='resume' :user='user')
  save-bar(
    ref='saveBar'
    :draft-value='resume' :store-value='storeResume'
    :save-disabled='$vv.$error'
    :update-request='updateResumeRequest'
    @save='saveButtonHandler' @reset='resetToStoreState'
  )
</template>

<script>
import _ from 'lodash';
import { mapGetters } from 'vuex';

import { vuelidateGroupMixin } from '@/components/forms/vuelidate-group';
import { mapRequestStatuses } from '@/utils/vuex-api-utils';

const components = {
  'resume-editor': require('@/components/forms/resume-editor').default,
};

export default {
  components,
  mixins: [vuelidateGroupMixin],
  data() {
    return {
      resume: {},
    };
  },
  computed: {
    ...mapGetters('authUser/resumes', {
      storeResume: 'currentResume',
    }),
    ...mapGetters('authUser/profile', {
      user: 'user',
    }),
    ...mapRequestStatuses({
      updateResumeRequest: 'authUser/resumes/UPDATE_RESUME',
    }),
  },
  watch: {
    storeResume: {
      // deep: true,
      handler() {
        this.resetToStoreState();
      },
    },
  },
  beforeMount() {
    this.resetToStoreState();
  },
  methods: {
    resetToStoreState() {
      this.resume = _.cloneDeep(this.storeResume);
      // console.log('Resume A: ', this.resume.address, this.storeResume.address);
    },
    async saveButtonHandler() {
      if (this.$hasError()) return;
      await this.$store.dispatchApiAction(
        'authUser/resumes/UPDATE_RESUME',
        this.resume,
      );
      this.resetToStoreState();
    },
  },
  beforeRouteLeave(to, from, next) {
    return next(this.$refs.saveBar.warnAboutUnsavedChanges());
  },
};
</script>

<style lang='less'>
</style>
