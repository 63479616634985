<template lang='pug'>
.shared-resume-preview
  .shared-resume-preview-resume
    resume-preview(:resume='sharedResume')
  .shared-resume-preview-cta
    router-link.create-resume-link(:to='{name: "resume-signup" }' target='_blank') Create Your Free Resume
    
  error-message(:request-status='loadSharedResumeRequest')
</template>

<script>
import { mapGetters } from 'vuex';
import { vuelidateGroupMixin } from '@/components/forms/vuelidate-group';
import { mapRequestStatuses } from '@/utils/vuex-api-utils';

const components = {
  'main-layout': require('@/app-public/common/layout/main-layout').default,
  'resume-preview': require('@/components/other/resume-preview').default,
};

export default {
  components,
  mixins: [vuelidateGroupMixin],
  props: {
    resumeShareKey: {
      type: String,
      required: true,
    },
  },
  metaInfo: {
    title: 'Resume',
  },
  watch: {
    resumeShareKey() {
      // resumeShareKey gets passed in by the router
      this.$store.dispatch(
        'selectedUser/resumes/loadSharedResume',
        this.resumeShareKey,
      );
    },
  },
  async beforeMount() {
    await this.$store.dispatch(
      'selectedUser/resumes/loadSharedResume',
      this.resumeShareKey,
    );
  },
  computed: {
    ...mapGetters('selectedUser/resumes', {
      sharedResume: 'sharedResume',
    }),
    ...mapRequestStatuses({
      loadSharedResumeRequest: 'selectedUser/resumes/LOAD_SHARED_RESUME',
    }),
  },
  methods: {},
};
</script>

<style lang='less'>
.shared-resume-preview {
  .shared-resume-preview-resume {
    margin: auto;
    width: 22cm; // ~width of printer paper.
    padding: 16px;
  }

  .shared-resume-preview-cta {
    text-align: center;
    margin: 20px 0;
  }
}
</style>
