<template lang='pug'>
.preview-resume-step
  h2 Choose Presentation
  form-group(label='Configuration')
    form-row
      form-input(
        v-model='resume.presentationType' type='dropdown'
        label='Presentation'
        :options='presentationTypeOptions'
        instructions='Choose your presentation type.'
      )
  h2 Preview
  .preview-content
    resume-preview(:resume='effectiveResume' :presentationTypeOverride='resume.presentationType')
  save-bar(
    ref='saveBar'
    :draft-value='resume' :store-value='storeResume'
    :save-disabled='$vv.$error'
    :update-request='updateResumeRequest'
    @save='saveButtonHandler' @reset='resetToStoreState'
  )
</template>

<script>
import _ from 'lodash';
import { mapGetters } from 'vuex';

import { vuelidateGroupMixin } from '@/components/forms/vuelidate-group';
import { mapRequestStatuses } from '@/utils/vuex-api-utils';

const components = {
  'resume-preview': require('@/components/other/resume-preview').default,
};

export default {
  components,
  mixins: [vuelidateGroupMixin],
  data() {
    return {
      resume: {},
    };
  },
  computed: {
    ...mapGetters('enums', [
      'presentationTypeOptions',
    ]),
    ...mapGetters('authUser/resumes', {
      storeResume: 'currentResume',
      effectiveResume: 'effectiveResume',
    }),
    ...mapRequestStatuses({
      updateResumeRequest: 'authUser/resumes/UPDATE_RESUME',
    }),
  },
  watch: {
    storeResume: {
      // deep: true,
      handler() {
        this.resetToStoreState();
      },
    },
  },
  beforeMount() {
    this.resetToStoreState();
  },
  methods: {
    resetToStoreState() {
      this.resume = _.cloneDeep(this.storeResume);
      console.log('Resume: ', this.resume);
    },
    async saveButtonHandler() {
      if (this.$hasError()) return;
      await this.$store.dispatchApiAction(
        'authUser/resumes/UPDATE_RESUME',
        this.resume,
      );
    },
  },
};
</script>

<style lang='less'>
.preview-resume-step {
  .preview-content {
    margin-bottom: 30px;
  }
}
</style>
